import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    OutlinedInput,
    Stack,
    Typography
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { red } from "@mui/material/colors";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import React, { Fragment, useEffect } from "react";
import { useDropzone } from 'react-dropzone';
import ReactSelect from "react-select";
import DateInput from "../CustomComponents/DateInput";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../../utils/utils";

const workPermitOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" }
]
const genderOption = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Other", value: "Others" }
]

const maritalStatusOptions = [
    { label: "Married", value: "Married" },
    { label: "Single", value: "Single" },
    { label: "Divorced", value: "Divorced" },
    { label: "Widowed", value: "Widowed" },
    { label: "Other", value: "Others" },
]

const validationSchema = Yup.object().shape({
    dateOfBirth: Yup.date().required("Date of Birth is Required").nullable(),
    gender: Yup.string().nullable(),
    maritalStatus: Yup.string().nullable(),
    bloodGroup: Yup.string().required("Blood Group is Required")
});

const PositionalTab = ({ onSubmit, onPrev, employeeData, files, setFiles, stepData, isUpdating }) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    });


    useEffect(() => {
        reset({ ...employeeData })
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': [],
        },
        onDrop: acceptedFiles => {
            if (acceptedFiles[0].size > 2000000) {
                toast.warn("File size is too large. (Maximum 2MB allowed)")
            } else {
                setFiles(acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })));
            }

            console.log(acceptedFiles)
        }
    });

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);
    console.log(stepData)

    if (stepData !== null) {
        setValue("dateOfBirth", stepData?.dob && new Date(stepData?.dob))
        setValue("gender", stepData?.gender)
        setValue("maritalStatus", stepData?.marital_status)
        setValue("workInCity", stepData?.work_in_city === 'null' ? '' : stepData?.work_in_city)
        setValue("cityOfResidence", stepData?.city_of_residence === 'null' ? '' : stepData?.city_of_residence)
        setValue("workPermit", stepData?.work_permit === 'null' ? '' : stepData?.work_permit)
        setValue("bloodGroup", stepData?.blood_group === 'null' ? '' : stepData?.blood_group)
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Controller
                        render={({ field, formState }) => (
                            <Fragment>
                                {/*<Box>*/}
                                {/*    <Typography color={!!formState.errors?.photograph ? red[700] : ""}>Photograph</Typography>*/}
                                {/*    <div {...getRootProps({className: 'dropzone'})}>*/}
                                {/*        <input {...getInputProps()} {...field} name={"photograph"}/>*/}
                                {/*        {(stepData !== null && files.length === 0) && <Stack sx={{my: 2}} alignItems={"center"}><Avatar src={`${API_BASE_URL}/${stepData?.image}`} sx={{height: 192, width: 192}} variant="rounded"/></Stack> }*/}
                                {/*        {files.length > 0 ?*/}
                                {/*            <Stack direction="row" justifyContent={"center"}>*/}
                                {/*                <Avatar src={files[0].preview} sx={{height: 192, width: 192}} variant="rounded"*/}
                                {/*                        onLoad={() => {*/}
                                {/*                            URL.revokeObjectURL(files[0].preview)*/}
                                {/*                        }}/>*/}
                                {/*            </Stack>*/}
                                {/*            :*/}
                                {/*            <Box sx={{maxHeight: "100px", border: "1px #c4c4c4 dashed"}}>*/}
                                {/*                <p>Drag 'n' drop some files here, or click to select files</p>*/}
                                {/*            </Box>*/}
                                {/*        }*/}

                                {/*    </div>*/}
                                {/*</Box>*/}
                                <Stack alignItems={"center"} sx={{ border: "1px dashed #C4C4C4", pt: 2 }}>
                                    {(stepData !== null && files.length < 1) && <Avatar variant={"rounded"} sx={{ width: 150, height: 150 }} src={`${API_BASE_URL}/${stepData?.image}`} />}
                                    {files.length > 0 &&
                                        <Avatar variant={"rounded"} sx={{ width: 150, height: 150 }} src={files[0]?.preview} />
                                    }
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        {
                                            isDragActive ?
                                                <p>Drop the files here ...</p> :
                                                <p>Drag 'n' drop some files here, or click <span style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}>Here</span> to select files</p>
                                        }
                                    </div>
                                </Stack>
                            </Fragment>

                        )}
                        name="photograph"
                        control={control}
                        defaultValue=""
                    />

                </Grid>
                <Grid item xs={4}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl fullWidth size={"small"} variant="outlined">
                                <Typography color={!!formState.errors?.dateOfBirth ? red[700] : ""}>Date of Birth*</Typography>
                                <DateInput inputProps={{ fullWidth: true }} inputValue={field.value} onChange={field.onChange} isError={!!formState.errors?.dateOfBirth} />
                                {!!formState.errors?.dateOfBirth ?
                                    <FormHelperText
                                        error>{errors?.dateOfBirth?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="dateOfBirth"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={4}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl fullWidth size={"small"}>
                                <Typography color={!!formState.errors?.gender ? red[700] : ""}>Gender</Typography>
                                <ReactSelect
                                    styles={
                                        {
                                            control: (base) => ({
                                                ...base,
                                                border: !!formState.errors?.gender ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                "&:hover": {
                                                    border: !!formState.errors?.gender ? "1px solid #D32F2F" : "1px solid #C4C4C4"
                                                }
                                            })
                                        }
                                    }
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={genderOption}
                                    value={field?.value !== "" ? genderOption?.filter(option => option?.value === field?.value)[0] : null}
                                    onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                                />
                                {!!formState.errors?.gender ?
                                    <FormHelperText
                                        error>{errors?.gender?.message}</FormHelperText> : ""}
                            </FormControl>
                        )}
                        name="gender"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={4}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl fullWidth size={"small"}>
                                <Typography color={!!formState.errors?.maritalStatus ? red[700] : ""}>Marital
                                    Status</Typography>
                                <ReactSelect
                                    styles={
                                        {
                                            control: (base) => ({
                                                ...base,
                                                border: !!formState.errors?.maritalStatus ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                "&:hover": {
                                                    border: !!formState.errors?.maritalStatus ? "1px solid #D32F2F" : "1px solid #C4C4C4"
                                                }
                                            })
                                        }
                                    }
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={maritalStatusOptions}
                                    value={field?.value !== "" ? maritalStatusOptions?.filter(option => option?.value === field?.value)[0] : null}
                                    onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                                />
                                {!!formState.errors?.maritalStatus ?
                                    <FormHelperText
                                        error>{errors?.maritalStatus?.message}</FormHelperText> : ""}
                            </FormControl>
                        )}
                        name="maritalStatus"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                {/*<Grid item xs={4}>*/}
                {/*    <Controller*/}
                {/*        render={({field, formState}) => (*/}
                {/*            <FormControl fullWidth size={"small"} variant="outlined">*/}
                {/*                <Typography color={!!formState.errors?.ethnicGroup ? red[700] : ""}>Ethnic*/}
                {/*                    Group*</Typography>*/}
                {/*                <OutlinedInput*/}
                {/*                    {...field}*/}
                {/*                    error={!!formState.errors?.ethnicGroup}*/}
                {/*                />*/}
                {/*                {!!formState.errors?.ethnicGroup ?*/}
                {/*                    <FormHelperText*/}
                {/*                        error>{errors?.ethnicGroup?.message}</FormHelperText> : ""}*/}

                {/*            </FormControl>*/}
                {/*        )}*/}
                {/*        name="ethnicGroup"*/}
                {/*        control={control}*/}
                {/*        defaultValue=""*/}
                {/*    />*/}
                {/*</Grid>*/}
                <Grid item xs={4}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl fullWidth size={"small"} variant="outlined">
                                <Typography color={!!formState.errors?.workInCity ? red[700] : ""}>Work in
                                    City</Typography>
                                <OutlinedInput
                                    {...field}
                                    error={!!formState.errors?.workInCity}
                                />
                                {!!formState.errors?.workInCity ?
                                    <FormHelperText
                                        error>{errors?.workInCity?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="workInCity"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={4}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl fullWidth size={"small"} variant="outlined">
                                <Typography color={!!formState.errors?.cityOfResidence ? red[700] : ""}>City of
                                    Residence</Typography>
                                <OutlinedInput
                                    {...field}
                                    error={!!formState.errors?.cityOfResidence}
                                />
                                {!!formState.errors?.cityOfResidence ?
                                    <FormHelperText
                                        error>{errors?.cityOfResidence?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="cityOfResidence"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={4}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl fullWidth size={"small"}>
                                <Typography color={!!formState.errors?.workPermit ? red[700] : ""}>Work
                                    Permit</Typography>
                                <ReactSelect
                                    styles={
                                        {
                                            control: (base) => ({
                                                ...base,
                                                border: !!formState.errors?.workPermit ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                "&:hover": {
                                                    border: !!formState.errors?.workPermit ? "1px solid #D32F2F" : "1px solid #C4C4C4"
                                                }
                                            })
                                        }
                                    }
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    name="color"
                                    options={workPermitOptions}
                                    value={field?.value !== "" ? workPermitOptions?.filter(option => option?.value === field?.value)[0] : setValue("workPermit", "No")}
                                    onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                                />
                                {!!formState.errors?.workPermit ?
                                    <FormHelperText
                                        error>{errors?.workPermit?.message}</FormHelperText> : ""}
                            </FormControl>
                        )}
                        name="workPermit"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={4}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl fullWidth size={"small"} variant="outlined">
                                <Typography color={!!formState.errors?.bloodGroup ? red[700] : ""}>Blood Group*</Typography>
                                <OutlinedInput
                                    {...field}
                                    type="text"
                                    error={!!formState.errors?.bloodGroup}
                                />
                                {!!formState.errors?.bloodGroup ?
                                    <FormHelperText
                                        error>{errors?.bloodGroup?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="bloodGroup"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
            </Grid>
            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
                {/* <Button variant="contained" onClick={onPrev}>Previous</Button> */}
                <Button disabled={isUpdating} variant="contained" type={"submit"}>{isUpdating && <CircularProgress size={20} sx={{ mr: 2 }} />} Save</Button>

            </Stack>
        </form>
    )
}

export default PositionalTab;