// noinspection SpellCheckingInspection

import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { FaUserTie, FaBuilding, FaChartPie, FaMoneyBillAlt, FaHandshake } from "react-icons/fa";
import { AiFillProject } from "react-icons/ai";
import { ImTree } from "react-icons/im";
import { RiBankFill } from "react-icons/ri";
import { HiReceiptTax } from "react-icons/hi";
import { IoMdSettings } from "react-icons/io";
import { BsCalendarXFill, BsCalendar2CheckFill } from "react-icons/bs";
import { MdExpandLess, MdExpandMore, MdDashboard } from "react-icons/md";
import { Collapse } from "@mui/material";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import { useEffect, useState } from "react";
import SidebarMenu from "./SidebarMenu";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";


const Sidebar = ({ open, onClose }) => {
    const location = useLocation()
    const [submenuControl, setSubmenuControl] = useState({
        clients: false,
        employees: false,
        projects: false,
        department: false,
        report: false,
        settings: false,
        attendance: false,
        payroll: false,
        leave: false,
        bank: false,
        loan: false,
        recruitment: false,
        settlement: false,
        chalan: false,
    })

    const [drawerWidth, setDrawerWidth] = useState(240)

    const handleClick = (key) => {
        let newValue = { ...submenuControl }
        const currentValue = newValue[key];
        Object.keys(newValue).forEach(keyItem => {
            newValue[keyItem] = false;
        });
        newValue[key] = !currentValue
        setSubmenuControl(newValue)
    }

    const { width } = useWindowDimensions();

    useEffect(() => {
        if (width > 960) {
            setDrawerWidth(240)
        } else {
            setDrawerWidth(400)
        }
    }, [width])

    useEffect(() => {
        if (location.pathname.includes("clients")) {
            let newValue = { ...submenuControl }
            newValue.clients = !newValue.clients
            setSubmenuControl(newValue)
        } else if (location.pathname.includes("employees")) {
            let newValue = { ...submenuControl }
            newValue.employees = !newValue.employees
            setSubmenuControl(newValue)
        } else if (location.pathname.includes("projects")) {
            let newValue = { ...submenuControl }
            newValue.projects = !newValue.projects
            setSubmenuControl(newValue)
        } else if (location.pathname.includes("department")) {
            let newValue = { ...submenuControl }
            newValue.department = !newValue.department
            setSubmenuControl(newValue)
        } else if (location.pathname.includes("report")) {
            let newValue = { ...submenuControl }
            newValue.report = !newValue.report
            setSubmenuControl(newValue)
        } else if (location.pathname.includes("settings")) {
            let newValue = { ...submenuControl }
            newValue.settings = !newValue.settings
            setSubmenuControl(newValue)
        } else if (location.pathname.includes("attendance")) {
            let newValue = { ...submenuControl }
            newValue.attendance = !newValue.attendance
            setSubmenuControl(newValue)
        } else if (location.pathname.includes("payroll")) {
            let newValue = { ...submenuControl }
            newValue.payroll = !newValue.payroll
            setSubmenuControl(newValue)
        } else if (location.pathname.includes("leave")) {
            let newValue = { ...submenuControl }
            newValue.leave = !newValue.leave
            setSubmenuControl(newValue)
        } else if (location.pathname.includes("bank")) {
            let newValue = { ...submenuControl }
            newValue.bank = !newValue.bank
            setSubmenuControl(newValue)
        } else if (location.pathname.includes("loan")) {
            let newValue = { ...submenuControl }
            newValue.loan = !newValue.loan
            setSubmenuControl(newValue)
        } else if (location.pathname.includes("recruitment")) {
            let newValue = { ...submenuControl }
            newValue.recruitment = !newValue.recruitment
            setSubmenuControl(newValue)
        } else if (location.pathname.includes("settlement")) {
            let newValue = { ...submenuControl }
            newValue.settlement = !newValue.settlement
            setSubmenuControl(newValue)
        } else if (location.pathname.includes("chalan")) {
            let newValue = { ...submenuControl }
            newValue.chalan = !newValue.chalan
            setSubmenuControl(newValue)
        }

    }, [])
    const handleClose = () => {
        if (width <= 960) {
            onClose()
        }
    }

    const { role } = useAuth();

    return (
        <Drawer
            open={open}
            onClose={onClose}
            variant={width > 960 ? "persistent" : "temporary"}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
        >
            <Toolbar />
            <Box className="sidebarContainer" sx={{ overflow: 'auto', mt: 5 }}>
                <List>
                    {/***** Dashboard *****/}
                    <SidebarMenu onClose={handleClose} directory="/dashboard/home" icon={<MdDashboard size={20} />}
                        menuTitle={"Dashboard"} />

                    {/***** Clients *****/}
                    {role === "Admin" &&
                        <>
                            <ListItemButton onClick={() => handleClick("clients")}>
                                <ListItemIcon>
                                    <FaBuilding size={20} />
                                </ListItemIcon>
                                <ListItemText primary="Clients" />
                                {submenuControl.clients ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.clients} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Add Client"}
                                        directory="/clients/add" />
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Manage Clients"}
                                        directory="/clients/manage" />
                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    }

                    {/***** Projects *****/}
                    <ListItemButton onClick={() => handleClick("projects")}>
                        <ListItemIcon>
                            <AiFillProject size={20} />
                        </ListItemIcon>
                        <ListItemText primary="Projects" />
                        {submenuControl.projects ? <MdExpandMore /> : <MdExpandLess />}
                    </ListItemButton>
                    <Collapse in={submenuControl.projects} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Add Project"}
                                directory="/projects/add" />
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Manage Projects"}
                                directory="/projects/manage" />
                            {/* <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Salary Percentage Configuration"}
                                directory="/projects/salary-percentage-config" /> */}
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Add PO"}
                                directory="/projects/add-po" />
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Manage PO"}
                                directory="/projects/manage-po" />
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Add Agreement"}
                                directory="/projects/add-agreement" />
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Manage Agreement"}
                                directory="/projects/manage-agreement" />

                            <Divider />
                        </List>
                    </Collapse>

                    {/***** Departments *****/}
                    <ListItemButton onClick={() => handleClick("department")}>
                        <ListItemIcon>
                            <ImTree size={20} />
                        </ListItemIcon>
                        <ListItemText primary="Departments" />
                        {submenuControl.department ? <MdExpandMore /> : <MdExpandLess />}
                    </ListItemButton>
                    <Collapse in={submenuControl.department} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Add Department"}
                                directory="/department/add" />
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Manage Departments"}
                                directory="/department/manage" />
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Add Designation"}
                                directory="/department/add-sub" />
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Manage Designation"}
                                directory="/department/manage-sub" />

                            <Divider />
                        </List>
                    </Collapse>

                    {/***** Employees *****/}
                    <ListItemButton onClick={() => handleClick("employees")}>
                        <ListItemIcon>
                            <FaUserTie size={20} />
                        </ListItemIcon>
                        <ListItemText primary="Employees" />
                        {submenuControl.employees ? <MdExpandMore /> : <MdExpandLess />}
                    </ListItemButton>
                    <Collapse in={submenuControl.employees} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Add Employees"}
                                directory="/employees/add" />
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Manage Employees"}
                                directory="/employees/manage" />
                            {/*<SidebarMenu onClose={handleClose} icon={""} menuTitle={"Employee Service Book"} directory="/employees/service-book"/>*/}
                            <Divider />
                        </List>
                    </Collapse>

                    {/***** Attendance *****/}
                    <ListItemButton onClick={() => handleClick("attendance")}>
                        <ListItemIcon>
                            <BsCalendar2CheckFill size={20} />
                        </ListItemIcon>
                        <ListItemText primary="Attendance" />
                        {submenuControl.attendance ? <MdExpandMore /> : <MdExpandLess />}
                    </ListItemButton>
                    <Collapse in={submenuControl.attendance} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Manage Attendance"}
                                directory="/attendance/manage" />
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Manage Attendance (Bulk)"}
                                directory="/attendance/manage/bulk  " />
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Office Schedule"}
                                directory="/attendance/office-schedule" />
                            <Divider />
                        </List>
                    </Collapse>

                    {/***** Leave *****/}
                    <ListItemButton onClick={() => handleClick("leave")}>
                        <ListItemIcon>
                            <BsCalendarXFill size={20} />
                        </ListItemIcon>
                        <ListItemText primary="Leave" />
                        {submenuControl.leave ? <MdExpandMore /> : <MdExpandLess />}
                    </ListItemButton>
                    <Collapse in={submenuControl.leave} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Weekly Holidays"}
                                directory="/leave/weekly-holidays" />
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Holidays"}
                                directory="/leave/holidays" />
                            <Divider />
                        </List>
                    </Collapse>

                    {/**** Hidden but might need later *****/}
                    {/*<ListItemButton onClick={() => handleClick("recruitment")}>*/}
                    {/*    <ListItemIcon>*/}
                    {/*        <RiUserSearchFill size={20}/>*/}
                    {/*    </ListItemIcon>*/}
                    {/*    <ListItemText primary="Recruitment" />*/}
                    {/*    {submenuControl.recruitment ? <MdExpandMore /> : <MdExpandLess />}*/}
                    {/*</ListItemButton>*/}
                    {/*<Collapse in={submenuControl.recruitment} timeout="auto" unmountOnExit>*/}
                    {/*    <List component="div" disablePadding>*/}
                    {/*        <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Add Candidate"} directory="/recruitment/add-candidate"/>*/}
                    {/*        <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Manage Candidate"} directory="/recruitment/manage-candidate"/>*/}
                    {/*        <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Candidate Shortlist"} directory="/recruitment/candidate-shortlist"/>*/}
                    {/*        <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Interview"} directory="/recruitment/interview"/>*/}
                    {/*        <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Candidate Selection"} directory="/recruitment/candidate-selection"/>*/}
                    {/*        <Divider/>*/}
                    {/*    </List>*/}
                    {/*</Collapse>*/}
                    {/**** Hidden but might need later *****/}


                    <>
                        <ListItemButton onClick={() => handleClick("bank")}>
                            <ListItemIcon>
                                <RiBankFill size={20} />
                            </ListItemIcon>
                            <ListItemText primary="Bank" />
                            {submenuControl.bank ? <MdExpandMore /> : <MdExpandLess />}
                        </ListItemButton>
                        <Collapse in={submenuControl.bank} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Add Bank"}
                                    directory="/bank/add" />
                                <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Bank List"}
                                    directory="/bank/list" />
                                <Divider />
                            </List>
                        </Collapse>
                    </>


                    <ListItemButton onClick={() => handleClick("chalan")}>
                        <ListItemIcon>
                            <HiReceiptTax size={20} />
                        </ListItemIcon>
                        <ListItemText primary="Tax Challan" />
                        {submenuControl.chalan ? <MdExpandMore /> : <MdExpandLess />}
                    </ListItemButton>
                    <Collapse in={submenuControl.chalan} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Generate Tax Challan"}
                                directory="/tax-challan/generate" />
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"List Tax Challan"}
                                directory="/tax-challan/list" />
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"108 Report"}
                                directory="/tax-challan/108" />
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"108A Report"}
                                directory="/tax-challan/108a" />
                            <Divider />
                        </List>
                    </Collapse>

                    {/**** Hidden but might need later *****/}
                    {/*<ListItemButton onClick={() => handleClick("loan")}>*/}
                    {/*    <ListItemIcon>*/}
                    {/*        <GiReceiveMoney size={20}/>*/}
                    {/*    </ListItemIcon>*/}
                    {/*    <ListItemText primary="Loan" />*/}
                    {/*    {submenuControl.loan ? <MdExpandMore /> : <MdExpandLess />}*/}
                    {/*</ListItemButton>*/}
                    {/*<Collapse in={submenuControl.loan} timeout="auto" unmountOnExit>*/}
                    {/*    <List component="div" disablePadding>*/}
                    {/*        <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Grant Loan"} directory="/loan/grant"/>*/}
                    {/*        <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Loan Report"} directory="/loan/report"/>*/}
                    {/*        <Divider/>*/}
                    {/*    </List>*/}
                    {/*</Collapse>*/}
                    {/**** Hidden but might need later *****/}


                    <ListItemButton onClick={() => handleClick("payroll")}>
                        <ListItemIcon>
                            <FaMoneyBillAlt size={20} />
                        </ListItemIcon>
                        <ListItemText primary="Payroll" />
                        {submenuControl.payroll ? <MdExpandMore /> : <MdExpandLess />}
                    </ListItemButton>
                    <Collapse in={submenuControl.payroll} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Generate Salary"}
                                directory="/payroll/generate" />
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Generate Salary Bulk"}
                                directory="/payroll/generate-bulk" />
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Manage Payroll"}
                                directory="/payroll/manage" />
                            <Divider />
                        </List>
                    </Collapse>

                    <ListItemButton onClick={() => handleClick("settlement")}>
                        <ListItemIcon>
                            <FaHandshake size={20} />
                        </ListItemIcon>
                        <ListItemText primary="Final Settlement" />
                        {submenuControl.settlement ? <MdExpandMore /> : <MdExpandLess />}
                    </ListItemButton>
                    <Collapse in={submenuControl.settlement} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"New Settlement"}
                                directory="/settlements/new" />
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Settled List"}
                                directory="/settlements/list" />
                            <Divider />
                        </List>
                    </Collapse>

                    <ListItemButton onClick={() => handleClick("report")}>
                        <ListItemIcon>
                            <FaChartPie size={20} />
                        </ListItemIcon>
                        <ListItemText primary="Reports" />
                        {submenuControl.report ? <MdExpandMore /> : <MdExpandLess />}
                    </ListItemButton>
                    <Collapse in={submenuControl.report} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Attendance Report"}
                                directory="/reports/attendance" />
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Leave Report"}
                                directory="/reports/leave" />
                            {/*<SidebarMenu onClose={handleClose} icon={""} menuTitle={"Project Wise Attendance"} directory="/reports/project-wise-attendance"/>*/}
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Daily Attendance"}
                                directory="/reports/daily-attendance" />
                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Employee Report"}
                                directory="/reports/employee" />
                            <Divider />
                        </List>
                    </Collapse>
                    {role === "Admin" &&
                        <>
                            <ListItemButton onClick={() => handleClick("settings")}>
                                <ListItemIcon>
                                    <IoMdSettings size={20} />
                                </ListItemIcon>
                                <ListItemText primary="Settings" />
                                {submenuControl.settings ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.settings} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Add Admin"}
                                        directory="/settings/add-admin" />
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Manage Admin"}
                                        directory="/settings/manage-admin" />
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Add KAM"}
                                        directory="/settings/add-kam" />
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Manage KAM"}
                                        directory="/settings/manage-kam" />
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Manage Permission"}
                                        directory="/settings/project-permission" />
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Manage Challan Year"}
                                        directory="/settings/challan-year" />
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Upload PDF File"}
                                        directory="/settings/upload-pdf" />
                                    {/*<SidebarMenu onClose={handleClose} icon={""} menuTitle={"Tax Settings"} directory="/settings/tax-settings"/>*/}
                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    }
                </List>
            </Box>
        </Drawer>
    )
}

export default Sidebar;