import {
    Box,
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl,
    IconButton, Menu, MenuItem,
    Paper, Slide,
    Stack,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TablePagination, TableRow,
    Typography
} from "@mui/material";
import { BsPlusLg, BsThreeDotsVertical } from "react-icons/bs";
import { BiEditAlt, } from "react-icons/bi";
import { AiFillWarning } from "react-icons/ai";
import { RiDeleteBin2Line } from "react-icons/ri";
import { useState } from "react";
import { useEffect } from "react";
import Axios from "../../api/Axios";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import { forwardRef } from "react";
import LoadingPage from "../LoadingPage/LoadingPage";
import DataNotFound from "../../components/DataNotFound";
import ReactSelect from "react-select";
import { getFilteringClients } from "../../api/commonApiCalls";
import SalaryConfigDialog from "../../components/dialogs/SalaryConfigDialog";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SalaryPercentageConfig = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [projects, setProjects] = useState([])
    const [additionalInfo, setAdditionalInfo] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedProject, setSelectedProject] = useState(null)
    const [shouldFetchData, setShouldFetchData] = useState(false)
    const [loading, setLoading] = useState(true)
    const [filteringClients, setFilteringClients] = useState([])
    const [selectedFilterClient, setSelectedFilterClient] = useState(null)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value)
    }
    const open = Boolean(anchorEl);
    const handleClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setSelectedProject(id)
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { token, role } = useAuth();

    //Getting Projects data
    useEffect(() => {
        const getProjects = async () => {
            let link = `/project?page=${currentPage}&limit=${rowsPerPage}`
            if (selectedFilterClient !== null) {
                link = link + `&hrm_client_id=${selectedFilterClient?.value}`
            }
            try {
                setLoading(true)
                const res = await Axios.get(link, {
                    headers: {
                        Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                    }
                });
                if (res.data.status === "success") {
                    setProjects(res.data.response_data.data)
                    const { data, ...rest } = res.data.response_data;
                    setAdditionalInfo(rest)
                } else {
                    setProjects([])
                    toast.warn("Something went wrong")
                }
                setLoading(false)
            } catch (error) {
                setProjects([])
                console.log(error)
                toast.warn("Something went wrong")
                setLoading(false)
            }
        }

        getProjects();
    }, [shouldFetchData, currentPage, rowsPerPage, selectedFilterClient, token])

    const handleChangePage = (e, newpage) => {
        setCurrentPage(newpage + 1)
        //setCurrentPage(currentPage + 1)
    }

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogOpen = () => {
        setDialogOpen(true);
        handleClose()
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    //delete client
    const deleteProject = async () => {
        // try {
        //     const res = await Axios.delete(`/project/delete/${selectedProject}`, {
        //         headers: {
        //             Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
        //         }
        //     });
        //     if (res.data.status === "success") {
        //         toast.success("Project Deleted");
        //         setShouldFetchData(!shouldFetchData);
        //         handleDialogClose();
        //     } else {
        //         setProjects([])
        //         toast.warn("Something went wrong")
        //     }
        // } catch (error) {
        //     console.log(error)
        //     toast.warn("Something went wrong")
        // }
    }

    //get client list for filtering
    useEffect(() => {
        getFilteringClients(setLoading, token, setFilteringClients)
    }, [token])


    return (
        loading ?
            <LoadingPage /> :
            <Box>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5" color="#0F3F62" sx={{ mt: 2 }}>Salary Percentage Configuration</Typography>
                    <Box>
                        {/*<Tooltip title="Grid View">*/}
                        {/*    <IconButton color={displayType === "grid" ? "primary" : "inherit"} aria-label="gird view"*/}
                        {/*                onClick={() => setDisplayType("grid")}>*/}
                        {/*        <BsFillGrid3X3GapFill/>*/}
                        {/*    </IconButton>*/}
                        {/*</Tooltip>*/}
                        {/*<Tooltip title="List View">*/}
                        {/*    <IconButton color={displayType === "list" ? "primary" : "inherit"} aria-label="List View"*/}
                        {/*                sx={{mx: 2}} onClick={() => setDisplayType("list")}>*/}
                        {/*        <BsListUl/>*/}
                        {/*    </IconButton>*/}
                        {/*</Tooltip>*/}
                        {role === "Admin" &&
                            <Button variant="contained" size={"small"}>
                                <Stack direction="row" alignItems={"center"}>
                                    <BsPlusLg /> <Typography sx={{ ml: 1 }} variant="subtitle1"
                                        onClick={() => {
                                            setSelectedProject(null);
                                            setOpenDialog(true);
                                        }}>Add
                                        Project</Typography>
                                </Stack>
                            </Button>
                        }
                    </Box>
                </Stack>
                <Box>
                    <TableContainer component={Paper} sx={{ my: 2, px: 5, py: 2 }}>
                        <Stack direction={"row"} justifyContent={"end"} sx={{ my: 2, mr: 2 }}>
                            <FormControl sx={{ width: "200px", ml: 2 }}>
                                <ReactSelect
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isClearable={true}
                                    isSearchable={true}
                                    placeholder={"Select Client"}
                                    name="color"
                                    options={filteringClients}
                                    defaultValue={selectedFilterClient}
                                    onChange={(selectedOption) => setSelectedFilterClient(selectedOption)}
                                />
                            </FormControl>

                            <FormControl sx={{ width: "200px", ml: 2 }}>
                                <ReactSelect
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isClearable={true}
                                    isSearchable={true}
                                    placeholder={"Select Project"}
                                    name="color"
                                    options={projects?.map(item => ({ value: item.project_fdb_id, label: item.name }))}
                                // defaultValue={selectedFilterClient}
                                // onChange={(selectedOption) => setSelectedFilterClient(selectedOption)}
                                />
                            </FormControl>
                        </Stack>
                        {projects.length > 0 ?
                            <>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table" size={"small"}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">SL.</TableCell>
                                            <TableCell align="left" width={200}>Client Name</TableCell>
                                            <TableCell align="left" width={250}>Project Name</TableCell>
                                            <TableCell align="center">Basic (%)</TableCell>
                                            <TableCell align="center">Medical(%)</TableCell>
                                            <TableCell align="center">House Rent(%)</TableCell>
                                            <TableCell align="center">Transport(%)</TableCell>
                                            <TableCell align="center">Lunch(%)</TableCell>
                                            <TableCell align="right">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {projects.map((project, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="left">{index + 1}</TableCell>
                                                <TableCell align="left" width={200}>{project?.hrm_client?.name}</TableCell>
                                                <TableCell align="left" width={250}>{project?.name}</TableCell>
                                                <TableCell
                                                    align="center">{55}</TableCell>
                                                <TableCell align="center">{10}</TableCell>
                                                <TableCell align="center">{25}</TableCell>
                                                <TableCell align="center">{5}</TableCell>
                                                <TableCell align="center">{5}</TableCell>
                                                <TableCell align="right">
                                                    <IconButton
                                                        id="basic-button"
                                                        aria-controls={open ? 'basic-menu' : undefined}
                                                        aria-haspopup="true"
                                                        aria-expanded={open ? 'true' : undefined}
                                                        onClick={(e) => handleClick(e, project.id)}
                                                    >
                                                        <BsThreeDotsVertical />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    component="div"
                                    count={additionalInfo?.total}
                                    page={additionalInfo?.current_page - 1}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </> :
                            <DataNotFound />
                        }
                    </TableContainer>

                </Box>

                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {/* <MenuItem onClick={() => navigate(`/projects/details/${selectedProject}`)}>
                        <BiDetail style={{ marginRight: "5px" }} /> Details
                    </MenuItem> */}
                    <MenuItem onClick={() => {
                        setAnchorEl(null);
                        setOpenDialog(true);
                    }}>
                        <BiEditAlt style={{ marginRight: "5px" }} /> Edit
                    </MenuItem>
                    <MenuItem onClick={handleDialogOpen}>
                        <RiDeleteBin2Line style={{ marginRight: "5px" }} /> Delete
                    </MenuItem>
                </Menu>



                <Dialog
                    open={dialogOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleDialogClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>
                        <Stack alignItems={"center"} justifyContent={"center"} direction={"row"}>
                            <AiFillWarning size={24} color={"#eed202"} />
                            <Typography sx={{ ml: 1 }} variant={"h5"}>Confirm Delete!</Typography>
                        </Stack>
                    </DialogTitle>
                    <Divider></Divider>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Are you sure you want to delete this user?
                            Once deleted, you will not get this client's data back.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"contained"} color={"error"} onClick={handleDialogClose}>Disagree</Button>
                        <Button variant={"contained"} color={"success"} onClick={deleteProject}>Agree</Button>
                    </DialogActions>
                </Dialog>

                {openDialog && <SalaryConfigDialog setOpenDialog={setOpenDialog} openDialog={openDialog} metadata={{ id: selectedProject }} />}
            </Box>
    )
}

export default SalaryPercentageConfig;