import {
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControl,
    FormHelperText,
    Grid, MenuItem,
    OutlinedInput, Select,
    Stack,
    Typography
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { red } from "@mui/material/colors";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import DateInput from "../CustomComponents/DateInput";
import { getValue } from "@testing-library/user-event/dist/utils";


const SalaryInfoTab = ({ onSubmit, onPrev, employeeData, stepData, isUpdating, includeOptions, setIncludeOptions }) => {

    const [isTaxRequired, setIsTaxRequired] = useState("No");
    const validationSchema = Yup.object().shape({
        basic: Yup.string().required("Basic salary is required"),
        tax: isTaxRequired === "yes" ? Yup.string().required("Basic salary is required") : null,
        // transportAllowance: Yup.number("Must be a number"),
        // medicalAllowance: Yup.number("Must be a number"),
        // houseRent: Yup.number("Must be a number"),

    });

    //react-hook-form
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        reset
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange",
        defaultValues: {
            taxApplicable: "No"
        }
    });

    const [allSalary, setAllSalary] = useState({
        basic: 0,
        transport: 0,
        medical: 0,
        house: 0,
        provident: 0,
        tax: 0,
        mobile: 0,
        conveyance: 0,
        lunch: 0,
        others: 0
    })

    console.log(includeOptions)

    const changeHandler = (value, key) => {
        const salaries = { ...allSalary }
        if (value.length > 0) {
            salaries[key] = parseInt(value)
            setAllSalary(salaries)
        } else {
            salaries[key] = 0
        }
        setAllSalary(salaries)
    }

    // useEffect(() => {
    //     reset({ ...employeeData })
    // }, [employeeData, reset])

    // useEffect(() => {
    //     if (allSalary.basic > 0 || allSalary.transport > 0 || allSalary.medical > 0 || allSalary.house > 0 || allSalary.mobile > 0 || allSalary.conveyance > 0 || allSalary.lunch > 0 || allSalary.others > 0 || allSalary.provident > 0 || allSalary.tax > 0) {
    //         const totalSalary = allSalary.basic + allSalary.transport + allSalary.medical + allSalary.house + allSalary.mobile + allSalary.conveyance + allSalary.lunch + allSalary.others - allSalary.provident - allSalary.tax;
    //         // setValue("gross", gross.toString())
    //         setValue("total_salary", totalSalary.toString())
    //     }
    // }, [allSalary, setValue])


    useEffect(() => {
        let grossSalary = 0;
        let totalSalary = 0;
        if (allSalary.basic > 0 || allSalary.transport > 0 || allSalary.medical > 0 || allSalary.house > 0 || allSalary.mobile > 0 || allSalary.conveyance > 0 || allSalary.lunch > 0 || allSalary.others > 0 || allSalary.provident > 0 || allSalary.tax > 0) {
            totalSalary = allSalary.basic + allSalary.transport + allSalary.medical + allSalary.house + allSalary.mobile + allSalary.conveyance + allSalary.lunch + allSalary.others - allSalary.provident - allSalary.tax;


            Boolean(includeOptions.basic) ? grossSalary += allSalary.basic : grossSalary += 0;
            Boolean(includeOptions.transport) ? grossSalary += allSalary.transport : grossSalary += 0;
            Boolean(includeOptions.medical) ? grossSalary += allSalary.medical : grossSalary += 0;
            Boolean(includeOptions.house) ? grossSalary += allSalary.house : grossSalary += 0;
            Boolean(includeOptions.mobile) ? grossSalary += allSalary.mobile : grossSalary += 0;
            Boolean(includeOptions.conveyance) ? grossSalary += allSalary.conveyance : grossSalary += 0;
            Boolean(includeOptions.lunch) ? grossSalary += allSalary.lunch : grossSalary += 0;
            Boolean(includeOptions.others) ? grossSalary += allSalary.others : grossSalary += 0;
        }
        // Applying the values
        setValue("gross", grossSalary.toString());
        setValue("total_salary", totalSalary.toString());

    }, [allSalary, setValue, includeOptions])





    // useEffect(() => {
    //     let basic = 0;
    //     let transport = 0;
    //     if (getValues().basic !== "") {
    //         basic = parseInt(getValues().basic)
    //     }
    //     if (getValues().transportAllowance !== "") {
    //         transport = parseInt(getValues().transportAllowance)
    //     }
    //     setValue("gross", basic + transport)
    // }, [getValues().basic, getValues().transportAllowance])


    useEffect(() => {
        if (stepData !== null) {
            setValue("basic", stepData?.basic_salary)
            setValue("transportAllowance", stepData?.transport_allowance)
            setValue("medicalAllowance", stepData?.medical_allowance)
            setValue("houseRent", stepData?.house_rent)
            setValue("mobileBill", stepData?.mobile_bill)
            setValue("conveyance", stepData?.conveyance_bill)
            setValue("lunch", stepData?.lunch_allowance)
            setValue("others", stepData?.others_bill)
            setValue("basic", stepData?.basic_salary)
            setValue("eidBonus", stepData?.bonus)
            setValue("taxApplicable", stepData?.tax_applicable)
            setValue("tax", stepData?.tax)
            setValue("otherDeductions", stepData?.others_deduction)
            setValue("Circle", stepData?.circle)
            setValue("zone", stepData?.zone)
            setValue("etinNo", stepData?.e_tin)
            setValue("taxReturnDate", stepData?.tax_return_date === null ? null : new Date(stepData?.tax_return_date === undefined ? null : stepData?.tax_return_date))
            setValue("gross", stepData?.gross_salary)
            setValue("total_salary", stepData?.total_salary)
            setIsTaxRequired(stepData?.tax_applicable)
            setAllSalary({
                basic: stepData?.basic_salary,
                transport: stepData?.transport_allowance,
                medical: stepData?.medical_allowance,
                house: stepData?.house_rent,
                provident: 0,
                tax: stepData?.tax,
                mobile: stepData?.mobile_bill,
                conveyance: stepData?.conveyance_bill,
                lunch: stepData?.lunch_allowance,
                others: stepData?.others_bill
            });

            // Updating the include/exclude option 
            setIncludeOptions({
                basic: stepData?.is_basic_salary_include || 0,
                transport: stepData?.is_transport_allowance_include || 0,
                medical: stepData?.is_medical_allowance_include || 0,
                house: stepData?.is_house_rent_include || 0,
                mobile: stepData?.is_mobile_bill_include || 0,
                conveyance: stepData?.is_conveyance_bill_include || 0,
                lunch: stepData?.is_lunch_allowance_include || 0,
                others: stepData?.is_others_bill_include || 0,
            });
        }

    }, [stepData, setValue, setIncludeOptions])


    // useEffect(() => {
    //     if(stepData !== null) {
    //         setIsTaxRequired(stepData?.tax_applicable)
    //     }
    // }, [stepData])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h6" sx={{ mb: 2 }}>Payments</Typography>

            <Grid container spacing={2}>
                <Grid item xs={3}>

                    <Grid container flexDirection={"row"} alignItems={'flex-end'}>
                        <Grid item xs={10.5}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth variant="outlined" size={"small"}>
                                        <Typography color={!!formState.errors?.basic ? red[700] : ""}>Basic Salary*</Typography>
                                        <OutlinedInput
                                            {...field}
                                            error={!!formState.errors?.basic}
                                            type="number"
                                            onChange={e => {
                                                field.onChange(e);
                                                changeHandler(e.target.value, "basic")
                                            }}
                                        />
                                        {!!formState.errors?.basic ?
                                            <FormHelperText
                                                error>{errors?.basic?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="basic"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={1.5} sx={{ border: '0.5px solid lightgray', borderRadius: 1, py: 0.1 }} justifyContent={'center'}>
                            <Checkbox
                                control={control}
                                name="is_basic_salary_include"
                                title={includeOptions.basic ? "Exclude" : "Include"}
                                size="small"
                                value={includeOptions.basic}
                                checked={Boolean(includeOptions.basic)}
                                onChange={() => setIncludeOptions((prev) => ({ ...prev, basic: Number(!includeOptions.basic) }))} />
                        </Grid>
                    </Grid>


                </Grid>
                <Grid item xs={3}>
                    <Grid container flexDirection={"row"} alignItems={'flex-end'}>
                        <Grid item xs={10.5}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth variant="outlined" size={"small"}>
                                        <Typography color={!!formState.errors?.transportAllowance ? red[700] : ""}>Transport
                                            Allowance</Typography>
                                        <OutlinedInput
                                            {...field}
                                            type={"number"}
                                            error={!!formState.errors?.transportAllowance}
                                            onChange={e => {
                                                field.onChange(e);
                                                changeHandler(e.target.value, "transport")
                                            }}
                                        />
                                        {!!formState.errors?.transportAllowance ?
                                            <FormHelperText
                                                error>{errors?.transportAllowance?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="transportAllowance"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={1.5} sx={{ border: '0.5px solid lightgray', borderRadius: 1, py: 0.1 }} justifyContent={'center'}>
                            <Checkbox
                                control={control}
                                name="is_transport_allowance_include"
                                size="small"
                                title={includeOptions.transport ? "Exclude" : "Include"}
                                value={includeOptions.transport}
                                checked={Boolean(includeOptions.transport)}
                                onChange={() => setIncludeOptions((prev) => ({ ...prev, transport: Number(!includeOptions.transport) }))} />
                        </Grid>
                    </Grid>



                </Grid>
                <Grid item xs={3}>
                    <Grid container flexDirection={"row"} alignItems={'flex-end'}>
                        <Grid item xs={10.5}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth variant="outlined" size={"small"}>
                                        <Typography color={!!formState.errors?.medicalAllowance ? red[700] : ""}>Medical
                                            Allowance</Typography>
                                        <OutlinedInput
                                            {...field}
                                            type={"number"}
                                            error={!!formState.errors?.medicalAllowance}
                                            onChange={e => {
                                                field.onChange(e);
                                                changeHandler(e.target.value, "medical")
                                            }}
                                        />
                                        {!!formState.errors?.medicalAllowance ?
                                            <FormHelperText
                                                error>{errors?.medicalAllowance?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="medicalAllowance"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={1.5} sx={{ border: '0.5px solid lightgray', borderRadius: 1, py: 0.1 }} justifyContent={'center'}>
                            <Checkbox
                                control={control}
                                name="is_medical_allowance_include"
                                size="small"
                                title={includeOptions.medical ? "Exclude" : "Include"}
                                value={includeOptions.medical}
                                checked={Boolean(includeOptions.medical)}
                                onChange={() => setIncludeOptions((prev) => ({ ...prev, medical: Number(!includeOptions.medical) }))} />
                        </Grid>
                    </Grid>


                </Grid>
                <Grid item xs={3}>
                    <Grid container flexDirection={"row"} alignItems={'flex-end'}>
                        <Grid item xs={10.5}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth variant="outlined" size={"small"}>
                                        <Typography color={!!formState.errors?.houseRent ? red[700] : ""}>House
                                            Rent</Typography>
                                        <OutlinedInput
                                            {...field}
                                            type={"number"}
                                            error={!!formState.errors?.houseRent}
                                            onChange={e => {
                                                field.onChange(e);
                                                changeHandler(e.target.value, "house")
                                            }}
                                        />
                                        {!!formState.errors?.houseRent ?
                                            <FormHelperText
                                                error>{errors?.houseRent?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="houseRent"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={1.5} sx={{ border: '0.5px solid lightgray', borderRadius: 1, py: 0.1 }} justifyContent={'center'}>
                            <Checkbox
                                control={control}
                                name="is_house_rent_include"
                                size="small"
                                title={includeOptions.house ? "Exclude" : "Include"}
                                value={includeOptions.house}
                                checked={Boolean(includeOptions.house)}
                                onChange={() => setIncludeOptions((prev) => ({ ...prev, house: Number(!includeOptions.house) }))} />
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={3}>

                    <Grid container flexDirection={"row"} alignItems={'flex-end'}>
                        <Grid item xs={10.5}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth variant="outlined" size={"small"}>
                                        <Typography color={!!formState.errors?.mobileBill ? red[700] : ""}>Mobile
                                            Bill</Typography>
                                        <OutlinedInput
                                            {...field}
                                            type={"number"}
                                            error={!!formState.errors?.mobileBill}
                                            onChange={e => {
                                                field.onChange(e);
                                                changeHandler(e.target.value, "mobile")
                                            }}
                                        />
                                        {!!formState.errors?.mobileBill ?
                                            <FormHelperText
                                                error>{errors?.mobileBill?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="mobileBill"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={1.5} sx={{ border: '0.5px solid lightgray', borderRadius: 1, py: 0.1 }} justifyContent={'center'}>
                            <Checkbox
                                control={control}
                                name="is_mobile_bill_include"
                                size="small"
                                title={includeOptions.mobile ? "Exclude" : "Include"}
                                checked={includeOptions.mobile}
                                value={Boolean(includeOptions.mobile)}
                                onChange={() => setIncludeOptions((prev) => ({ ...prev, mobile: Number(!includeOptions.mobile) }))} />
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={3}>
                    <Grid container flexDirection={"row"} alignItems={'flex-end'}>
                        <Grid item xs={10.5}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth variant="outlined" size={"small"}>
                                        <Typography color={!!formState.errors?.conveyance ? red[700] : ""}>Conveyance
                                            Bill</Typography>
                                        <OutlinedInput
                                            {...field}
                                            type={"number"}
                                            error={!!formState.errors?.conveyance}
                                            onChange={e => {
                                                field.onChange(e);
                                                changeHandler(e.target.value, "conveyance")
                                            }}
                                        />
                                        {!!formState.errors?.conveyance ?
                                            <FormHelperText
                                                error>{errors?.conveyance?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="conveyance"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={1.5} sx={{ border: '0.5px solid lightgray', borderRadius: 1, py: 0.1 }} justifyContent={'center'}>
                            <Checkbox
                                control={control}
                                name="is_conveyance_bill_include"
                                size="small"
                                title={includeOptions.conveyance ? "Exclude" : "Include"}
                                value={includeOptions.conveyance}
                                checked={Boolean(includeOptions.conveyance)}
                                onChange={() => setIncludeOptions((prev) => ({ ...prev, conveyance: Number(!includeOptions.conveyance) }))} />
                        </Grid>
                    </Grid>



                </Grid>
                <Grid item xs={3}>
                    <Grid container flexDirection={"row"} alignItems={'flex-end'}>
                        <Grid item xs={10.5}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth variant="outlined" size={"small"}>
                                        <Typography color={!!formState.errors?.lunch ? red[700] : ""}>Lunch
                                            Allowance</Typography>
                                        <OutlinedInput
                                            {...field}
                                            type={"number"}
                                            error={!!formState.errors?.lunch}
                                            onChange={e => {
                                                field.onChange(e);
                                                changeHandler(e.target.value, "lunch")
                                            }}
                                        />
                                        {!!formState.errors?.lunch ?
                                            <FormHelperText
                                                error>{errors?.lunch?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="lunch"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={1.5} sx={{ border: '0.5px solid lightgray', borderRadius: 1, py: 0.1 }} justifyContent={'center'}>
                            <Checkbox
                                control={control}
                                name="is_lunch_allowance_include"
                                size="small"
                                title={includeOptions.lunch ? "Exclude" : "Include"}
                                value={includeOptions.lunch}
                                checked={Boolean(includeOptions.lunch)}
                                onChange={() => setIncludeOptions((prev) => ({ ...prev, lunch: Number(!includeOptions.lunch) }))} />
                        </Grid>
                    </Grid>


                </Grid>
                <Grid item xs={3}>
                    <Grid container flexDirection={"row"} alignItems={'flex-end'}>
                        <Grid item xs={10.5}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth variant="outlined" size={"small"}>
                                        <Typography color={!!formState.errors?.others ? red[700] : ""}>Others</Typography>
                                        <OutlinedInput
                                            {...field}
                                            type={"number"}
                                            error={!!formState.errors?.others}
                                            onChange={e => {
                                                field.onChange(e);
                                                changeHandler(e.target.value, "others")
                                            }}
                                        />
                                        {!!formState.errors?.others ?
                                            <FormHelperText
                                                error>{errors?.others?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="others"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={1.5} sx={{ border: '0.5px solid lightgray', borderRadius: 1, py: 0.1 }} justifyContent={'center'}>
                            <Checkbox
                                control={control}
                                name="is_others_bill_include"
                                size="small"
                                title={includeOptions.others ? "Exclude" : "Include"}
                                value={includeOptions.others}
                                checked={Boolean(includeOptions.others)}
                                onChange={() => setIncludeOptions((prev) => ({ ...prev, others: Number(!includeOptions.others) }))} />
                        </Grid>
                    </Grid>


                </Grid>

            </Grid>

            <Typography variant="h6" sx={{ my: 2 }}>Bonuses</Typography>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl fullWidth variant="outlined" size={"small"}>
                                <Typography color={!!formState.errors?.eidBonus ? red[700] : ""}>Festival
                                    Bonus(%)</Typography>
                                <OutlinedInput
                                    type={"number"}
                                    {...field}
                                    error={!!formState.errors?.eidBonus}
                                    onChange={e => {
                                        field.onChange(e);
                                        changeHandler(e.target.value, "eidBonus")
                                    }}
                                />
                                {!!formState.errors?.eidBonus ?
                                    <FormHelperText
                                        error>{errors?.eidBonus?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="eidBonus"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
            </Grid>

            <Typography variant="h6" sx={{ my: 2 }}>Deductions</Typography>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl fullWidth variant="outlined" size={"small"}>
                                <Typography color={!!formState.errors?.provident ? red[700] : ""}>Provident
                                    Fund</Typography>
                                <OutlinedInput
                                    type={"number"}
                                    {...field}
                                    error={!!formState.errors?.provident}
                                    onChange={e => {
                                        field.onChange(e);
                                        changeHandler(e.target.value, "provident")
                                    }}
                                />
                                {!!formState.errors?.provident ?
                                    <FormHelperText
                                        error>{errors?.provident?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="provident"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={3}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl fullWidth variant="outlined" size={"small"}>
                                <Typography color={!!formState.errors?.taxApplicable ? red[700] : ""}>Tax
                                    Applicable?</Typography>
                                <Select
                                    size={"small"}
                                    labelId="taxRequired"
                                    id="taxRequiredDropdown"
                                    value={isTaxRequired}
                                    onChange={(e) => {
                                        field.onChange(e.target.value)
                                        setIsTaxRequired(e.target.value)
                                    }}
                                >
                                    <MenuItem value={'Yes'}>Yes</MenuItem>
                                    <MenuItem value={'No'}>No</MenuItem>
                                </Select>
                                {!!formState.errors?.taxApplicable ?
                                    <FormHelperText
                                        error>{errors?.taxApplicable?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="taxApplicable"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={3}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl fullWidth variant="outlined" size={"small"}>
                                <Typography color={!!formState.errors?.tax ? red[700] : ""}>Income Tax</Typography>
                                <OutlinedInput
                                    {...field}
                                    type={"number"}
                                    error={!!formState.errors?.tax}
                                    disabled={isTaxRequired === 'No'}
                                    onChange={e => {
                                        field.onChange(e);
                                        changeHandler(e.target.value, "tax")
                                    }}
                                />
                                {!!formState.errors?.tax ?
                                    <FormHelperText
                                        error>{errors?.tax?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="tax"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={3}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl fullWidth variant="outlined" size={"small"}>
                                <Typography
                                    color={!!formState.errors?.otherDeductions ? red[700] : ""}>Others</Typography>
                                <OutlinedInput
                                    {...field}
                                    type={"number"}
                                    error={!!formState.errors?.otherDeductions}
                                    onChange={e => {
                                        field.onChange(e);
                                        changeHandler(e.target.value, "tax")
                                    }}
                                />
                                {!!formState.errors?.otherDeductions ?
                                    <FormHelperText
                                        error>{errors?.otherDeductions?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="otherDeductions"
                        control={control}
                        defaultValue=""
                    />
                </Grid>

            </Grid>

            <Typography variant="h6" sx={{ my: 2 }}>Net Payable</Typography>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl fullWidth variant="outlined" size={"small"}>
                                <Typography color={!!formState.errors?.gross ? red[700] : ""}>Gross Salary</Typography>
                                <OutlinedInput
                                    {...field}
                                    error={!!formState.errors?.gross}
                                    disabled
                                />
                                {!!formState.errors?.gross ?
                                    <FormHelperText
                                        error>{errors?.gross?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="gross"
                        control={control}
                        defaultValue=""
                    />
                </Grid>

                <Grid item xs={3}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl fullWidth variant="outlined" size={"small"}>
                                <Typography color={!!formState.errors?.total_salary ? red[700] : ""}>Total Salary</Typography>
                                <OutlinedInput
                                    {...field}
                                    error={!!formState.errors?.total_salary}
                                    disabled
                                />
                                {!!formState.errors?.total_salary ?
                                    <FormHelperText
                                        error>{errors?.total_salary?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="total_salary"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
            </Grid>

            <Divider sx={{ mt: 2 }} />
            <Typography variant="h6" sx={{ my: 2 }}>Other Informations (Tax)</Typography>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl fullWidth variant="outlined" size={"small"}>
                                <Typography color={!!formState.errors?.etinNo ? red[700] : ""}>E-TIN No.</Typography>
                                <OutlinedInput
                                    {...field}
                                    error={!!formState.errors?.etinNo}
                                    onChange={e => {
                                        field.onChange(e);
                                    }}
                                />
                                {!!formState.errors?.etinNo ?
                                    <FormHelperText
                                        error>{errors?.etinNo?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="etinNo"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={3}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl fullWidth variant="outlined" size={"small"}>
                                <Typography color={!!formState.errors?.Circle ? red[700] : ""}>Circle</Typography>
                                <OutlinedInput
                                    {...field}
                                    error={!!formState.errors?.Circle}
                                    onChange={e => {
                                        field.onChange(e);
                                    }}
                                />
                                {!!formState.errors?.Circle ?
                                    <FormHelperText
                                        error>{errors?.Circle?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="Circle"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={3}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl fullWidth variant="outlined" size={"small"}>
                                <Typography color={!!formState.errors?.zone ? red[700] : ""}>Zone</Typography>
                                <OutlinedInput
                                    {...field}
                                    error={!!formState.errors?.zone}
                                    onChange={e => {
                                        field.onChange(e);
                                    }}
                                />
                                {!!formState.errors?.zone ?
                                    <FormHelperText
                                        error>{errors?.zone?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="zone"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={3}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl fullWidth size={"small"} variant="outlined">
                                <Typography color={!!formState.errors?.taxReturnDate ? red[700] : ""}>Tax Return
                                    Date</Typography>

                                <DateInput inputProps={{ fullWidth: true }} inputValue={field.value} onChange={field.onChange} isError={!!formState.errors?.taxReturnDate} />
                                {!!formState.errors?.taxReturnDate ?
                                    <FormHelperText
                                        error>{errors?.taxReturnDate?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="taxReturnDate"
                        control={control}
                        defaultValue=""
                    />
                </Grid>

            </Grid>
            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
                {/* <Button variant="contained" onClick={onPrev}>Previous</Button> */}
                <Button disabled={isUpdating} variant="contained" type={"submit"}>{isUpdating && <CircularProgress size={20} sx={{ mr: 2 }} />} Save</Button>

            </Stack>
        </form>
    )
}

export default SalaryInfoTab;