import {Button, ButtonGroup, Divider, Grid, Paper, Stack, Switch, Typography} from "@mui/material";

const NotificationSettingsTab = () => {
    return(
        <Paper elevation={0} sx={{p: 3}}>
            <Grid spacing={2} container alignItems={"center"} justifyContent={"center"}>
                <Grid item xs={12}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography>Some Notification Settings One</Typography>
                        <Switch defaultChecked />
                    </Stack>
                    <Divider sx={{my: 1}}/>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography>Some Notification Settings Two</Typography>
                        <Switch defaultChecked />
                    </Stack>
                    <Divider sx={{my: 1}}/>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography>Some Notification Settings Three</Typography>
                        <Switch defaultChecked />
                    </Stack>
                    <Divider sx={{my: 1}}/>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography>Some Notification Settings Four</Typography>
                        <Switch defaultChecked />
                    </Stack>
                    <Divider sx={{my: 1}}/>
                </Grid>

                <Grid item xs={12}>
                    <ButtonGroup variant="contained" fullWidth sx={{mt: 4}}>
                        <Button color={"success"}>Save</Button>
                        <Button color={"inherit"}>Cancel</Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default NotificationSettingsTab;