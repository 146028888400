import { Button, CircularProgress, Divider, Paper, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { BsFilePdf } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import Axios from '../../api/Axios';
import { useAuth } from '../../hooks/useAuth';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { API_BASE_URL } from '../../utils/utils';

const UploadFile = () => {
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [pdfFiles, setPdfFiles] = useState([]);
    const { token } = useAuth();


    useEffect(() => {
        const getPdfFiles = async () => {
            try {
                const { data } = await Axios.post(`setting`,
                    {
                        name: [
                            "privacy_policy",
                            "terms_and_conditions"
                        ]
                    },
                    {
                        headers: {
                            Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                        }
                    });
                if (data?.status === "success") {
                    setPdfFiles(data?.response_data);
                } else {
                    toast.warn("Something went wrong")
                }
            } catch (error) {
                if (error?.response?.data?.message === "Data not found.") {
                    toast.warn(error.response.data.message)
                } else {
                    console.log(error)
                }
            }
        }

        getPdfFiles();
    }, [token])


    const handleFileSubmit = async (e) => {
        e.preventDefault();
        console.log(e.target.privacy_policy)
        setLoading(true)
        try {
            const file = e.target.privacy_policy?.files[0];

            const formData = new FormData();
            formData.append('privacy_policy', file);

            const { data } = await Axios.post(`setting/create/update/file`, formData,
                {
                    headers: {
                        Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                        'Content-Type': 'multipart/form-data'
                    }
                });
            if (data?.status === "success") {

            } else {
                toast.warn("Something went wrong")
            }
            setLoading(false);
        } catch (error) {
            if (error?.response?.data?.message === "Data not found.") {
                toast.warn(error?.response?.data?.message)
            } else {
                console.log(error)
            }
            setLoading(false)

        }
    }
    const handleFileSubmitTNC = async (e) => {
        e.preventDefault();
        setLoading2(true)
        try {
            const file = e.target.terms_and_conditions.files[0];

            const formData = new FormData();
            formData.append('terms_and_conditions', file);

            const res = await Axios.post(`setting/create/update/file`, formData,
                {
                    headers: {
                        Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                        'Content-Type': 'multipart/form-data'
                    }
                });
            if (res.data?.status === "success") {

            } else {
                toast.warn("Something went wrong")
            }
            setLoading2(false);
        } catch (error) {
            if (error?.response?.data?.message === "Data not found.") {
                toast.warn(error?.response?.data?.message)
            } else {
                console.log(error)
            }
            setLoading2(false)

        }
    }



    const handleDownload = (fileName) => {
        const anchor = document.createElement('a');
        anchor.download = true;
        anchor.target = '_black';
        anchor.href = `${API_BASE_URL}/${pdfFiles?.[fileName]}`;;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };



    return (
        <Paper elevation={0} sx={{ px: 3, py: 4, mt: 1, minHeight: '80vh' }}>
            <>
                <Stack maxWidth={{ md: "60%" }} flexDirection={{ xs: "column", md: "row" }} alignItems={{ md: 'center' }} justifyContent={{ md: "space-between" }} gap={2}>
                    <Typography fontWeight={"bold"}>Privacy Policy</Typography>

                    <form onSubmit={handleFileSubmit}>
                        <Stack flexDirection={{ xs: "column", md: "row" }} alignItems={{ md: 'center' }} gap={2}>
                            <TextField
                                name='privacy_policy'
                                type='file'
                                inputProps={{ accept: '.pdf', name: 'privacy_policy' }} />

                            {loading ? <Button type='submit' variant='contained' disabled> <CircularProgress size={15} sx={{ mr: 1, color: 'white' }} /> Upload</Button> :
                                <Button type='submit' variant='contained'>Upload</Button>}
                        </Stack>
                    </form>
                </Stack>

                {pdfFiles?.['privacy_policy'] &&
                    <Stack flexDirection={"row"} alignItems={"center"} gap={1} title="Upload new file to change existing." >
                        <BsFilePdf cursor={"pointer"} />
                        <Typography onClick={() => handleDownload('privacy_policy')} sx={{ cursor: 'pointer' }} >Privacy Policy</Typography>
                    </Stack>}
            </>

            <Divider sx={{ mt: 2 }} />

            <>
                <Stack maxWidth={{ md: "60%" }} flexDirection={{ xs: "column", md: "row" }} alignItems={{ md: 'center' }} justifyContent={{ md: "space-between" }} gap={2} mt={4}>
                    <Typography fontWeight={"bold"}>Terms & Conditions</Typography>

                    <form onSubmit={handleFileSubmitTNC}>
                        <Stack flexDirection={{ xs: "column", md: "row" }} alignItems={{ md: 'center' }} gap={2}>
                            <TextField
                                name='terms_and_conditions'
                                type='file'
                                inputProps={{ accept: '.pdf', name: 'terms_and_conditions' }} />

                            {loading2 ? <Button type='submit' variant='contained' disabled> <CircularProgress size={15} sx={{ mr: 1, color: 'white' }} /> Upload</Button> :
                                <Button type='submit' variant='contained'>Upload</Button>}
                        </Stack>
                    </form>
                </Stack>

                {pdfFiles?.['privacy_policy'] && <Stack flexDirection={"row"} alignItems={"center"} gap={1} title="Upload new file to change existing." >
                    <BsFilePdf cursor={"pointer"} />
                    <Typography onClick={() => handleDownload("terms_and_conditions")} sx={{ cursor: 'pointer' }} >Terms & Conditions</Typography>
                </Stack>}
            </>


        </Paper>
    );
};

export default UploadFile;