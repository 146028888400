import {
    Avatar,
    Button,
    ButtonGroup,
    FormControl, FormHelperText,
    Grid,
    OutlinedInput,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { red } from "@mui/material/colors";
import { useAuth } from "../../hooks/useAuth";
import { useCallback, useEffect, useState } from "react";
import Axios from "../../api/Axios";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { API_BASE_URL } from "../../utils/utils";

const validationSchema = Yup.object().shape({});

const UpdateProfileTab = () => {
    //react-hook-form
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange"
    });
    const { user, token, refreshUser } = useAuth()

    const [currentUser, setCurrentUser] = useState({})
    const [files, setFiles] = useState([]);

    useEffect(() => {
        const getLoggedInUser = async () => {
            try {
                const res = await Axios.get(`me`, {
                    headers: {
                        Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                    }
                });
                if (res.data.status === "success") {
                    setCurrentUser(res.data.response_data)
                    setValue("name", res?.data?.response_data?.name)
                    setValue("email", res?.data?.response_data?.email)
                    setValue("phone", res?.data?.response_data?.phone)
                    setValue("address", res?.data?.response_data?.address)
                    //console.log(departmentTreeViewFormat(res.data.response_data))
                } else {
                    setCurrentUser({})
                    toast.warn("Unable to fetch user data")
                }
            } catch (error) {
                console.log(error)
                toast.warn("Something went wrong")
            }
        }

        getLoggedInUser()
    }, [])

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("email", data.email);
        formData.append("phone", data.phone);
        formData.append("address", data.address);
        formData.append("image", files.length > 0 ? files[0] : '');
        try {
            const res = await Axios.post(`profile/update/${user?.id}`,
                formData, {
                headers: {
                    Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                }
            })
            if (res?.data?.status === "success") {
                toast.success("Profile Update");
                refreshUser();
                //navigate("/clients/manage")
            }
        } catch (error) {
            console.log(error)
            toast.warn("Something went wrong");
        }
    }


    useEffect(() => {
        setValue("name", user?.name)
        setValue("email", user?.email)
        setValue("phone", user?.phone)
        setValue("address", user?.address)
    }, [user])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    })

    return (
        <Paper elevation={0} sx={{ p: 3 }}>


            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid spacing={2} container alignItems={"center"} justifyContent={"center"}>
                    <Grid item xs={12} justifyContent={"center"}>
                        <Stack alignItems={"center"}>
                            <Avatar variant={"rounded"} sx={{ width: 150, height: 150 }} src={files?.length > 0 ? files[0]?.preview : `${API_BASE_URL}/${currentUser?.image}`} alt={user?.name} />
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {
                                    isDragActive ?
                                        <p>Drop the files here ...</p> :
                                        <p>Drag 'n' drop some files here, or click <span style={{ color: "blue", textDecoration: "underline" }}>Here</span> to select files</p>
                                }
                            </div>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth variant="outlined" size="small">
                                    <Typography
                                        color={!!formState.errors?.name ? red[700] : ""}>Fullname</Typography>
                                    <OutlinedInput
                                        {...field}
                                        error={!!formState.errors?.startDate}
                                    />
                                    {!!formState.errors?.name ?
                                        <FormHelperText
                                            error>{errors?.name?.message}</FormHelperText> : ""}

                                </FormControl>
                            )}
                            name="name"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth variant="outlined" sx={{ mt: 2 }} size="small">
                                    <Typography color={!!formState.errors?.email ? red[700] : ""}>Email</Typography>
                                    <OutlinedInput
                                        {...field}
                                        error={!!formState.errors?.email}
                                    />
                                    {!!formState.errors?.email ?
                                        <FormHelperText
                                            error>{errors?.email?.message}</FormHelperText> : ""}

                                </FormControl>
                            )}
                            name="email"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth variant="outlined" size="small">
                                    <Typography
                                        color={!!formState.errors?.phone ? red[700] : ""}>Phone</Typography>
                                    <OutlinedInput
                                        {...field}
                                        error={!!formState.errors?.startDate}
                                    />
                                    {!!formState.errors?.phone ?
                                        <FormHelperText
                                            error>{errors?.phone?.message}</FormHelperText> : ""}

                                </FormControl>
                            )}
                            name="phone"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth variant="outlined" size="small">
                                    <Typography
                                        color={!!formState.errors?.address ? red[700] : ""}>Address</Typography>
                                    <TextField
                                        multiline
                                        rows={3}
                                        {...field}
                                        error={!!formState.errors?.address}
                                    />
                                    {!!formState.errors?.address ?
                                        <FormHelperText
                                            error>{errors?.address?.message}</FormHelperText> : ""}

                                </FormControl>
                            )}
                            name="address"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonGroup variant="contained" fullWidth>
                            <Button color={"success"} type={"submit"}>Save</Button>
                            <Button color={"inherit"}>Cancel</Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </form>


        </Paper>
    )
}

export default UpdateProfileTab