import {
    Avatar,
    Box, Button,
    Container,
    FormControl, FormHelperText,
    Grid, IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import {blue} from "@mui/material/colors";
import {useState} from "react";
import {AiFillEyeInvisible, AiFillEye} from "react-icons/ai"
import {MdAlternateEmail} from "react-icons/md"
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useAuth} from "../../hooks/useAuth";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import Axios from "../../api/Axios";
import {toast} from "react-toastify";

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .required('Email is required')
        .email("Email is not valid"),
});

const ForgotPasswordPage = () => {
    //states
    const [loading, setLoading] = useState(false)
    const auth = useAuth()
    const navigate = useNavigate()
    //react-hook-form
    const {
        control,
        handleSubmit,
        formState: {errors}
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data) => {
        setLoading(true)
        try {
            const res = await Axios.post(`send-OTP`,
                {
                    email: data.email,
                })
            if (res?.data?.status === "success") {
                toast.success("OTP Sent, Check Your Email");
                navigate("/otp-insert", {state: data});
            }
        } catch (error) {
            console.log(error)
            if(error.response.status === 422){
                toast.warn("Email does not exist, contact with admin");
            } else {
                toast.error("Something went wrong, try again");
            }

        }finally {
            setLoading(false)
        }
    };
    useEffect(() => {
        if (auth.token) {
            navigate("/dashboard/home");
        } else {
            const token = localStorage.getItem("token");
            if (token !== null) {
                navigate("/dashboard/home");
            }
        }
    }, [localStorage.getItem("token")]);
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item md={6} sx={{display: {xs: 'none', md: 'block'}}}>
                    <Paper elevation={4} sx={{bgcolor: blue[200], height: "98vh"}}>
                        <Stack justifyContent="center" alignItems="center" sx={{height: "100%"}}>
                            <img height="400px" src="/assets/images/login/forgot_pass.svg" alt=""/>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Container>
                        <Stack justifyContent="center" alignItems="center" sx={{height: "98vh"}}>
                            <img style={{maxHeight: "60px", margin: "0 auto", display: "inline"}} src="/FDB-Logo.png" alt="FDB Logo"/>
                            <Box sx={{maxWidth: 450}}>
                                <Typography sx={{my: 2}} variant="h6" textAlign="center">Forgot Password</Typography>
                                <Typography sx={{mb: 3}} variant="body2" textAlign="center">
                                    Forgot your password? No problem. Just let us know your email address and we will email you a
                                    password code that will allow you to create a new one.
                                </Typography>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Controller
                                        render={({field, formState}) => (
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel htmlFor="login-email">Email</InputLabel>
                                                <OutlinedInput
                                                    id="login-email"
                                                    {...field}
                                                    error={!!formState.errors?.email}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                edge="end"
                                                            >
                                                                <MdAlternateEmail/>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Email"
                                                />
                                                {!!formState.errors?.email ?
                                                    <FormHelperText
                                                        error>{errors?.email?.message}</FormHelperText> : ""}

                                            </FormControl>
                                        )}
                                        name="email"
                                        control={control}
                                        defaultValue=""
                                    />

                                    {/*<Link to="/dashboard">*/}
                                    {/*    <Button variant="text" sx={{mb: 3, color: blue[600]}}>Guest Login</Button>*/}
                                    {/*</Link>*/}
                                    <Button disabled={loading} sx={{mt: 3}} size="large" fullWidth variant="contained" type="submit">Request Reset</Button>
                                </form>
                            </Box>
                        </Stack>
                    </Container>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ForgotPasswordPage;