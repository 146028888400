import {
    Box,
    Button, ButtonGroup,
    Checkbox,
    Divider, FormControl,
    FormControlLabel,
    FormGroup, FormHelperText, Grid,
    IconButton, MenuItem,
    Modal, OutlinedInput,
    Paper, Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow, TextField,
    Typography
} from "@mui/material";
import {FaEdit} from "react-icons/fa"
import {MdDeleteForever} from "react-icons/md"
import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {red} from "@mui/material/colors";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    maxHeight: 800,
    overflowY: "scroll",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const validationSchema = Yup.object().shape({
    employeeName: Yup.string().required('Cannot generate report without employee name'),
    month: Yup.string().required('Cannot generate report without month'),
    year: Yup.string().required('Cannot generate report without year'),
});

const CandidateSelectionPage = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const {
        control,
        handleSubmit,
        formState: {errors},
        reset
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange"
    });
    const onSubmit = (data) => console.log(data);

    return (<Paper elevation={2} sx={{p: 2}}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography variant="h5">Grant Loans</Typography>

            <Button sx={{mr: 1}} variant={"contained"} size={"small"} color={"success"} onClick={handleOpen}>Add Candidate Selection</Button>


        </Stack>
        <Divider sx={{my: 2}}></Divider>
        <TableContainer>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">SL</TableCell>
                        <TableCell align="center">Name</TableCell>
                        <TableCell align="center">Candidate Id</TableCell>
                        <TableCell align="center">Employee Id</TableCell>
                        <TableCell align="center">Position</TableCell>
                        <TableCell align="center">Selection Terms</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell align="left">1</TableCell>
                        <TableCell align="center">Alice Oseman</TableCell>
                        <TableCell align="center">d8a7f89sd7</TableCell>
                        <TableCell align="center">23</TableCell>
                        <TableCell align="center">1</TableCell>
                        <TableCell align="center">Project Manager</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={100}
                page={1}
                // onPageChange={handleChangePage}
                rowsPerPage={10}
                // onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Add Selection
                </Typography>
                <Divider/>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} sx={{my: 2}} alignItems="center" justifyContent="end">
                        <Grid item xs={12}>
                            <Controller
                                render={({field, formState}) => (
                                    <FormControl size={"small"} fullWidth>
                                        <Typography color={!!formState.errors?.position ? red[700]: ""}>Candidate Name*</Typography>
                                        <Select
                                            {...field}
                                            error={!!formState.errors?.position}
                                        >
                                            <MenuItem value={"intern software engineer"}>Candidate 1</MenuItem>
                                            <MenuItem value={"trainee software engineer"}>Candidate 2</MenuItem>
                                            <MenuItem value={"associate software engineer"}>Candidate 3</MenuItem>
                                            <MenuItem value={"software engineer"}>Candidate 4</MenuItem>
                                            <MenuItem value={"senior software engineer"}>Candidate 5</MenuItem>
                                        </Select>
                                        {!!formState.errors?.position ?
                                            <FormHelperText
                                                error>{errors?.position?.message}</FormHelperText> : ""}
                                    </FormControl>
                                )}
                                name="position"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                render={({field, formState}) => (
                                    <FormControl size={"small"} fullWidth>
                                        <Typography color={!!formState.errors?.position ? red[700]: ""}>Job Position</Typography>
                                        <Select
                                            {...field}
                                            error={!!formState.errors?.position}
                                        >
                                            <MenuItem value={"intern software engineer"}>Intern Software Engineer</MenuItem>
                                            <MenuItem value={"trainee software engineer"}>Trainee Software Engineer</MenuItem>
                                            <MenuItem value={"associate software engineer"}>Associate Software Engineer</MenuItem>
                                            <MenuItem value={"software engineer"}>Software Engineer</MenuItem>
                                            <MenuItem value={"senior software engineer"}>Senior Software Engineer</MenuItem>
                                        </Select>
                                        {!!formState.errors?.position ?
                                            <FormHelperText
                                                error>{errors?.position?.message}</FormHelperText> : ""}
                                    </FormControl>
                                )}
                                name="position"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                render={({field, formState}) => (
                                    <FormControl fullWidth variant="outlined" size="small">
                                        <Typography
                                            color={!!formState.errors?.employeeName ? red[700] : ""}>Selection Terms</Typography>
                                        <TextField
                                            {...field}
                                            multiline
                                            minRows={3}
                                            error={!!formState.errors?.startDate}
                                        />
                                        {!!formState.errors?.employeeName ?
                                            <FormHelperText
                                                error>{errors?.employeeName?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="employeeName"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button sx={{mt: 3}} variant="contained" type="submit" color="success" fullWidth>Add to Selection</Button>
                        </Grid>
                    </Grid>

                </form>
            </Box>
        </Modal>
    </Paper>)
}

export default CandidateSelectionPage;