export const API_BASE_URL = window.location.host === 'hrm.frontdeskbd.com' ? 'https://apilivefdbhrm.gdnserver.com' : 'https://fdbhrmapi.gdnserver.com';

export const monthsInWords = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

export const getCurrentMonthInWords = () => {
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();

    return monthsInWords[currentMonthIndex];
}
