import {Avatar, Box, Paper, Stack, Typography} from "@mui/material";

export default function DataNotFound () {
    return (
        <Box>
            <Paper elevation={0} sx={{p: 4}}>
                <Stack alignItems={"center"}>
                    <Avatar src="/assets/images/no-data.png" alt="No Data" variant={"square"} sx={{height: "128px", width: "128px"}}/>
                    <Typography variant={"h4"} textAlign={"center"} sx={{mt: 2}}>No Data Found!</Typography>
                </Stack>
            </Paper>
        </Box>
    );
}