import {
    Box,
    Button, Checkbox,
    FormControl,
    FormHelperText,
    Grid,
    MenuItem, OutlinedInput,
    Paper,
    Select,
    Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField,
    Typography
} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {red} from "@mui/material/colors";
import {Calendar} from "react-big-calendar";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";

const validationSchema = Yup.object().shape({
    employeeName: Yup.string().required('Cannot generate report without employee name'),
    month: Yup.string().required('Cannot generate report without month'),
    year: Yup.string().required('Cannot generate report without year'),
});

const LoanReportPage = () => {
    const {
        control,
        handleSubmit,
        formState: {errors},
        reset
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange"
    });
    const onSubmit = (data) => console.log(data);
    return (
        <Box>
            <Paper elevation={3} sx={{p: 3}}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} sx={{my: 2}} alignItems="center" justifyContent="end">
                        <Grid item xs={12} md={4}>
                            <Controller
                                render={({field, formState}) => (
                                    <FormControl fullWidth variant="outlined" size="small">
                                        <Typography color={!!formState.errors?.employeeName ? red[700] : ""}>Select
                                            Employee*</Typography>
                                        <Select
                                            {...field}
                                            error={!!formState.errors?.employeeName}
                                        >
                                            <MenuItem value={"bd"}>Bangladesh</MenuItem>
                                            <MenuItem value={"in"}>India</MenuItem>
                                            <MenuItem value={"pk"}>Pakistan</MenuItem>
                                        </Select>
                                        {!!formState.errors?.employeeName ?
                                            <FormHelperText
                                                error>{errors?.employeeName?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="employeeName"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                render={({field, formState}) => (
                                    <FormControl fullWidth size="small">
                                        <Typography color={!!formState.errors?.month ? red[700] : ""}>Approve Date</Typography>
                                        <OutlinedInput
                                            {...field}
                                            type={"date"}
                                            error={!!formState.errors?.month}
                                        />
                                        {!!formState.errors?.month ?
                                            <FormHelperText
                                                error>{errors?.month?.message}</FormHelperText> : ""}
                                    </FormControl>
                                )}
                                name="month"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Controller
                                render={({field, formState}) => (
                                    <FormControl fullWidth size="small">
                                        <Typography color={!!formState.errors?.month ? red[700] : ""}>Repayment From</Typography>
                                        <OutlinedInput
                                            {...field}
                                            type={"date"}
                                            error={!!formState.errors?.month}
                                        />
                                        {!!formState.errors?.month ?
                                            <FormHelperText
                                                error>{errors?.month?.message}</FormHelperText> : ""}
                                    </FormControl>
                                )}
                                name="year"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Button sx={{mt: 3}} variant="contained" type="submit" color="success" fullWidth>Manage
                                Attendance</Button>
                        </Grid>
                    </Grid>

                </form>
            </Paper>
            <Paper elevation={3} sx={{p: 3, mt: 2}}>
                <TableContainer>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">SL</TableCell>
                                <TableCell align="center">Name</TableCell>
                                <TableCell align="center">Employee Id</TableCell>
                                <TableCell align="center">Total Loan</TableCell>
                                <TableCell align="center">Total Amount</TableCell>
                                <TableCell align="right">Repayment Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
                                <TableRow key={item}>
                                    <TableCell align="left">1</TableCell>
                                    <TableCell align="center">Alice Oseman</TableCell>
                                    <TableCell align="center">1</TableCell>
                                    <TableCell
                                        align="center">1</TableCell>
                                    <TableCell
                                        align="center">20000/-</TableCell>
                                    <TableCell
                                        align="right">20000/-</TableCell>
                                </TableRow>
                            ))}

                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        count={100}
                        page={1}
                        // onPageChange={handleChangePage}
                        rowsPerPage={10}
                        // onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Paper>
        </Box>
    )
}

export default LoanReportPage;