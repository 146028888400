import { useRoutes, Navigate } from "react-router-dom";
import { lazy, Suspense } from "react";
import Layout from "./Layout/Layout";
import LoginPage from "./Pages/LoginPage/LoginPage";
import NotFoundPage from "./Pages/NotFoundPage/NotFoundPage";
// import ProjectWiseAttendance from "./Pages/ReportPage/ProjectWiseAttendance";
import GrantLoanPage from "./Pages/Loan/GrantLoanPage";
import LoanReportPage from "./Pages/Loan/LoanReportPage";
import AddCandidatePage from "./Pages/Recruitment/AddCandidatePage";
import ManageCandidate from "./Pages/Recruitment/ManageCandidate";
import CandidateShortlist from "./Pages/Recruitment/CandidateShortlist";
import InterviewPage from "./Pages/Recruitment/InterviewPage";
import CandidateSelectionPage from "./Pages/Recruitment/CandidateSelectionPage";
import AccountSettingsPage from "./Pages/AccountSettings/AccountSettings";
import AuthGuard from "./components/authGuard/authGuard";
import UnauthorizedPage from "./Pages/UnauthorizedPage";
import LoadingPage from "./Pages/LoadingPage/LoadingPage";
import ForgotPassword from "./Pages/LoginPage/ForgotPassword";
import OTPInput from "./Pages/LoginPage/OTPInput";
import SalaryPercentageConfig from "./Pages/Projects/SalaryPercentageConfig";
import UploadFile from "./Pages/Settings/UploadFile";

//***** Dashboard Routes *****//
const HomePage = lazy(() => import("./Pages/HomePage/HomePage"));

//***** Client Routes *****//
const ClientsAddPage = lazy(() => import("./Pages/Clients/ClientsAddPage"));
const ClientsManagePage = lazy(() => import("./Pages/Clients/ClientsManagePage"));
const ClientsEditPage = lazy(() => import("./Pages/Clients/ClientsEditPage"));
const ClientDetailsPage = lazy(() => import("./Pages/Clients/ClientDetailsPage"));

//***** Project Routes *****//
const ProjectsAddPage = lazy(() => import("./Pages/Projects/ProjectsAddPage"));
const ProjectsManagePage = lazy(() => import("./Pages/Projects/ProjectsManagePage"));
const ProjectsEditPage = lazy(() => import("./Pages/Projects/ProjectsEditPage"));
const ProjectDetailsPage = lazy(() => import("./Pages/Projects/ProjectDetailsPage"));

//***** PO Routes *****//
const AddPOPage = lazy(() => import("./Pages/Projects/AddPOPage"));
const POManagePage = lazy(() => import("./Pages/Projects/POManagePage"));
const UpdatePOPage = lazy(() => import("./Pages/Projects/UpdatePOPage"));
const PODetailsPage = lazy(() => import("./Pages/Projects/PODetailsPage"));

//***** Agreement Routes *****//
const AddAgreementPage = lazy(() => import("./Pages/Projects/AddAgreementPage"));
const AgreementManagePage = lazy(() => import("./Pages/Projects/AgreementManagePage"));
const UpdateAgreementPage = lazy(() => import("./Pages/Projects/UpdateAgreementPage"));

//***** Department Routes *****//
const DepartmentAddPage = lazy(() => import("./Pages/Department/DepartmentAddPage"));
const DepartmentManagePage = lazy(() => import("./Pages/Department/DepartmentManagePage"));
const DepartmentEditPage = lazy(() => import("./Pages/Department/DepartmentEditPage"));

//***** Designation Routes *****//
const SubDepartmentAddPage = lazy(() => import("./Pages/Department/DesignationAddPage"));
const DesignationEditPage = lazy(() => import("./Pages/Department/DesignationEditPage"));
const DesignationManagePage = lazy(() => import("./Pages/Department/DesignationManagePage"));

//***** Employee Routes *****//
const EmployeeManagePage = lazy(() => import("./Pages/Employees/EmployeeManagePage"));
const EmployeeAddPage = lazy(() => import("./Pages/Employees/EmployeeAddPage"));
const EmployeeServiceBook = lazy(() => import("./Pages/Employees/EmployeeServiceBook"));

//***** Attendance Routes *****//
const ManageAttendancePage = lazy(() => import("./Pages/Attendance/ManageAttendancePage"));
const ManageBulkAttendance = lazy(() => import("./Pages/Attendance/ManageBulkAttendance"));
const OfficeSchedulePage = lazy(() => import("./Pages/Attendance/OfficeSchedulePage"));

//***** Leave Routes *****//
const WeeklyHolidayPage = lazy(() => import("./Pages/Leaves/WeeklyHolidayPage"));
const HolidaysPage = lazy(() => import("./Pages/Leaves/HolidaysPage"));

//***** Bank Routes *****//
const AddBankAccountPage = lazy(() => import("./Pages/Bank/AddBankAccountPage"));
const BankListPage = lazy(() => import("./Pages/Bank/BankListPage"));
const EditBankAccountPage = lazy(() => import("./Pages/Bank/EditBankAccountPage"));

//***** Tax Chalan Routes *****//
const SalaryChart = lazy(() => import("./Pages/Payroll/SalaryChart"));
const Report108Page = lazy(() => import("./Pages/TaxChalan/Report108Page"));
const Report108APage = lazy(() => import("./Pages/TaxChalan/Report108APage"));
const GenerateTaxChalanPage = lazy(() => import("./Pages/TaxChalan/GenerateTaxChalanPage"));
const TaxChallanList = lazy(() => import("./Pages/TaxChalan/TaxChallanList"));
const ChallanReport = lazy(() => import("./Pages/TaxChalan/ChallanReport"));
const ChallanView = lazy(() => import("./Pages/TaxChalan/ChallanView"));

//***** Payroll Routes *****//
const GenerateSalaryPage = lazy(() => import("./Pages/Payroll/GenerateSalaryPage"));
const ManagePayrollPage = lazy(() => import("./Pages/Payroll/ManagePayrollPage"));
const PayslipPage = lazy(() => import("./Pages/Payroll/Payslip"));
const PayslipBanglaPage = lazy(() => import("./Pages/Payroll/PayslipBangla"));
const EditGenerateSalaryEmployeeTable = lazy(() =>
    import("./Pages/Payroll/EditGenerateSalaryEmployeeTable")
);
const GenerateSalaryBulk = lazy(() => import("./Pages/Payroll/GenerateSalaryBulk"));
const GenerateSalaryEmployeeTable = lazy(() =>
    import("./Pages/Payroll/GenerateSalaryEmployeeTable")
);

//***** Settlement Routes *****//
const SettlementListPage = lazy(() => import("./Pages/Settlements/SettlementListPage"));
const NewSettlementPage = lazy(() => import("./Pages/Settlements/NewSettlementPage"));
const SettlementPage = lazy(() => import("./Pages/Settlements/Settlement"));
const EditSettlementPage = lazy(() => import("./Pages/Settlements/EditSettlementPage"));

//***** Report Routes *****//
const AttendanceReportPage = lazy(() => import("./Pages/ReportPage/AttendanceReportPage"));
const LeaveReportPage = lazy(() => import("./Pages/ReportPage/LeaveReportPage"));
const DailyAttendanceReport = lazy(() => import("./Pages/ReportPage/DailyAttendanceReport"));
const EmployeeReportPage = lazy(() => import("./Pages/ReportPage/EmployeeReportPage"));
const LeaveReportPageTwo = lazy(() => import("./Pages/ReportPage/LeaveReportPageTwo"));

//***** Settings Routes *****//
const AddKAMPage = lazy(() => import("./Pages/Settings/AddKAMPage"));
const ManageKAMPage = lazy(() => import("./Pages/Settings/ManageKAMPage"));
const EditKAMPage = lazy(() => import("./Pages/Settings/EditKAMPage"));
const ManagePermission = lazy(() => import("./Pages/Settings/ManagePermission"));
const TaxSettingsPage = lazy(() => import("./Pages/Settings/TaxSettingsPage"));
const AddAdminPage = lazy(() => import("./Pages/Settings/AddAdminPage"));
const ManageAdminPage = lazy(() => import("./Pages/Settings/ManageAdminPage"));
const EditAdminPage = lazy(() => import("./Pages/Settings/EditAdminPage"));
const ManageChallanYear = lazy(() => import("./Pages/Settings/ManageTaxYear"));

//***** Profile Routes *****//
const ProfilePage = lazy(() => import("./Pages/Profile/ProfilePage"));

const Router = () => {
    return useRoutes([
        {
            path: "/unauthorized",
            element: <Layout />,
            children: [
                {
                    path: "",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <UnauthorizedPage />
                        </AuthGuard>
                    ),
                },
            ],
        },
        {
            path: "/",
            element: (
                <AuthGuard redirectRoute={"/login/"}>
                    <Navigate to={"/dashboard/home"} />
                </AuthGuard>
            ),
        },
        {
            path: "/dashboard",
            element: <Layout />,
            children: [
                {
                    path: "",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Navigate to={"/dashboard/home"} />
                        </AuthGuard>
                    ),
                },
                {
                    path: "home",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                <HomePage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
            ],
        },
        {
            path: "/clients",
            element: <Layout />,
            children: [
                {
                    path: "",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Navigate to={"/clients/manage"} />
                        </AuthGuard>
                    ),
                },
                {
                    path: "manage",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                <ClientsManagePage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "details/:id",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <ClientDetailsPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "add",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                <ClientsAddPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "edit/:id",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                <ClientsEditPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
            ],
        },
        {
            path: "/employees",
            element: <Layout />,
            children: [
                {
                    path: "",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Navigate to={"/employees/manage"} />
                        </AuthGuard>
                    ),
                },
                {
                    path: "manage",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <EmployeeManagePage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "add",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <EmployeeAddPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "edit/:id",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                <EmployeeAddPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "service-book",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Navigate to={"/employees/manage"} />
                        </AuthGuard>
                    ),
                },
                {
                    path: "service-book/:id",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                <EmployeeServiceBook />
                            </Suspense>{" "}
                        </AuthGuard>
                    ),
                },
            ],
        },
        {
            path: "/recruitment",
            element: <Layout />,
            children: [
                {
                    path: "",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Navigate to={"/recruitment/manage-candidate"} />
                        </AuthGuard>
                    ),
                },
                {
                    path: "add-candidate",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <AddCandidatePage />
                        </AuthGuard>
                    ),
                },
                {
                    path: "manage-candidate",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <ManageCandidate />
                        </AuthGuard>
                    ),
                },
                {
                    path: "candidate-shortlist",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <CandidateShortlist />
                        </AuthGuard>
                    ),
                },
                {
                    path: "interview",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <InterviewPage />
                        </AuthGuard>
                    ),
                },
                {
                    path: "candidate-selection",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <CandidateSelectionPage />
                        </AuthGuard>
                    ),
                },
            ],
        },
        {
            path: "/bank",
            element: <Layout />,
            children: [
                {
                    path: "",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Navigate to={"/bank/list"} />
                        </AuthGuard>
                    ),
                },
                {
                    path: "add",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                <AddBankAccountPage />
                            </Suspense>{" "}
                        </AuthGuard>
                    ),
                },
                {
                    path: "edit/:id",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                <EditBankAccountPage />
                            </Suspense>{" "}
                        </AuthGuard>
                    ),
                },
                {
                    path: "list",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                <BankListPage />
                            </Suspense>{" "}
                        </AuthGuard>
                    ),
                },
            ],
        },
        {
            path: "/loan",
            element: <Layout />,
            children: [
                {
                    path: "",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Navigate to={"/loan/report"} />
                        </AuthGuard>
                    ),
                },
                {
                    path: "grant",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <GrantLoanPage />
                        </AuthGuard>
                    ),
                },
                {
                    path: "report",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <LoanReportPage />
                        </AuthGuard>
                    ),
                },
            ],
        },
        {
            path: "/projects",
            element: <Layout />,
            children: [
                {
                    path: "",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Navigate to={"/projects/manage"} />
                        </AuthGuard>
                    ),
                },
                {
                    path: "manage",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <ProjectsManagePage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "salary-percentage-config",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <SalaryPercentageConfig />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "manage-po",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <POManagePage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "manage-agreement",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <AgreementManagePage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "add",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <ProjectsAddPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "add-po",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <AddPOPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "add-agreement",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <AddAgreementPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "edit",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Navigate to={"/projects/manage"} />
                        </AuthGuard>
                    ),
                },
                {
                    path: "edit/:id",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <ProjectsEditPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "edit-po/:id",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <UpdatePOPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "edit-agreement/:id",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <UpdateAgreementPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "details",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Navigate to={"/projects/manage"} />
                        </AuthGuard>
                    ),
                },
                {
                    path: "po-details/:id",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <PODetailsPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "details/:id",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <ProjectDetailsPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
            ],
        },
        {
            path: "/department",
            element: <Layout />,
            children: [
                {
                    path: "",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Navigate to={"/employees/manage"} />
                        </AuthGuard>
                    ),
                },
                {
                    path: "manage",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                <DepartmentManagePage />
                            </Suspense>
                            <Suspense fallback={<LoadingPage />}></Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "add",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                <DepartmentAddPage />
                            </Suspense>
                            <Suspense fallback={<LoadingPage />}></Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "edit/:id",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                <DepartmentEditPage />
                            </Suspense>
                            <Suspense fallback={<LoadingPage />}></Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "manage-sub",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                <DesignationManagePage />
                            </Suspense>
                            <Suspense fallback={<LoadingPage />}></Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "add-sub",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                <SubDepartmentAddPage />
                            </Suspense>
                            <Suspense fallback={<LoadingPage />}></Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "edit-sub/:id",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                <DesignationEditPage />
                            </Suspense>
                            <Suspense fallback={<LoadingPage />}></Suspense>
                        </AuthGuard>
                    ),
                },
            ],
        },
        {
            path: "/reports",
            element: <Layout />,
            children: [
                {
                    path: "",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Navigate to={"/reports/attendance"} />
                        </AuthGuard>
                    ),
                },
                {
                    path: "attendance",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                <AttendanceReportPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "leave",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                <LeaveReportPageTwo />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "leave-two",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                <LeaveReportPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "employee",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                <EmployeeReportPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                // {path: "project-wise-attendance", element: <AuthGuard redirectRoute={"/login/"}><ProjectWiseAttendance/></AuthGuard>},
                {
                    path: "daily-attendance",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                <DailyAttendanceReport />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
            ],
        },
        {
            path: "/settings",
            element: <Layout />,
            children: [
                {
                    path: "",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Navigate to={"/settings/add-kam"} />
                        </AuthGuard>
                    ),
                },
                {
                    path: "add-admin",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <AddAdminPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "edit-admin/:id",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <EditAdminPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "add-kam",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <AddKAMPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "edit-kam/:id",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <EditKAMPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "manage-admin",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <ManageAdminPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "manage-kam",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <ManageKAMPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "project-permission",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <ManagePermission />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "tax-settings",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <TaxSettingsPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "challan-year",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <ManageChallanYear />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "upload-pdf",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <UploadFile />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
            ],
        },
        {
            path: "/attendance",
            element: <Layout />,
            children: [
                {
                    path: "",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Navigate to={"/attendance/manage"} />
                        </AuthGuard>
                    ),
                },
                {
                    path: "manage",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <ManageAttendancePage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "manage/bulk",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <ManageBulkAttendance />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "office-schedule",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <OfficeSchedulePage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
            ],
        },
        {
            path: "/leave",
            element: <Layout />,
            children: [
                {
                    path: "",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Navigate to={"/leave/weekly-holidays"} />
                        </AuthGuard>
                    ),
                },
                {
                    path: "weekly-holidays",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <WeeklyHolidayPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "holidays",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <HolidaysPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
            ],
        },
        {
            path: "/payroll",
            element: <Layout />,
            children: [
                {
                    path: "",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Navigate to={"/payroll/manage"} />
                        </AuthGuard>
                    ),
                },
                {
                    path: "manage",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <ManagePayrollPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "generate",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <GenerateSalaryPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "generate-bulk",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <GenerateSalaryBulk />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "generate/list/:clientID/:projectID/:month",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <GenerateSalaryEmployeeTable />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "edit/list/:clientID/:projectID/:month/:sheetId",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <EditGenerateSalaryEmployeeTable />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "manage/payslip/:id",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <PayslipPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "manage/payslip-bangla/:id",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <PayslipBanglaPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "generate/chart/:id",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <SalaryChart />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
            ],
        },
        {
            path: "/settlements",
            element: <Layout />,
            children: [
                {
                    path: "",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Navigate to={"/settlement/list"} />
                        </AuthGuard>
                    ),
                },
                {
                    path: "list",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <SettlementListPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "new",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <NewSettlementPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "edit/:id",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <EditSettlementPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "settlement/:id",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <SettlementPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
            ],
        },
        {
            path: "/tax-challan",
            element: <Layout />,
            children: [
                {
                    path: "",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Navigate to={"/tax-challan/generate"} />
                        </AuthGuard>
                    ),
                },
                {
                    path: "generate",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <GenerateTaxChalanPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "list",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <TaxChallanList />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "challan-report",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <ChallanReport />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                //{path: "challan-report", element: <AuthGuard redirectRoute={"/login/"}><ChalanReportEditable/></AuthGuard> },
                {
                    path: "108",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <Report108Page />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "108A",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <Report108APage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
                {
                    path: "view/:id",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                {" "}
                                <ChallanView />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
            ],
        },
        {
            path: "/login",
            element: <LoginPage />,
        },
        {
            path: "/forgot-password",
            element: <ForgotPassword />,
        },
        {
            path: "/otp-insert",
            element: <OTPInput />,
        },
        {
            path: "/profile",
            element: <Layout />,
            children: [
                {
                    path: "",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                <ProfilePage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
            ],
        },
        {
            path: "/account-settings",
            element: <Layout />,
            children: [
                {
                    path: "",
                    element: (
                        <AuthGuard redirectRoute={"/login/"}>
                            <Suspense fallback={<LoadingPage />}>
                                <AccountSettingsPage />
                            </Suspense>
                        </AuthGuard>
                    ),
                },
            ],
        },
        {
            path: "*",
            element: <NotFoundPage />,
        },
    ]);
};

export default Router;
