import axios from "axios";

const Axios = axios.create({
    baseURL: window.location.host === 'hrm.frontdeskbd.com' ? process.env.REACT_APP_LIVE_BASE_URL : process.env.REACT_APP_TEST_BASE_URL,
    timeout: 60000,
});

Axios.interceptors.response.use(
    function (response) {
        return response;
    }, function (error) {
        if (error.response.status === 403) {
            window.location.href = "/unauthorized"
        }
        if (error.response.status === 401) {
            console.log(error)
            localStorage.clear();
            window.location.href = "/login"
        }
        return Promise.reject(error);
    }
)

export default Axios;