import {
    Button,
    CircularProgress,
    FormControl,
    Grid,
    OutlinedInput,
    Stack,
    Typography
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { useEffect, useState } from "react";

const validationSchema = Yup.object().shape({});

const PositionalTab = ({ onSubmit, onPrev, employeeData, setCustomValuesParent, customValuesParent, stepData, isUpdating }) => {
    const {
        handleSubmit,
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    });

    const [customFieldName, setCustomFieldName] = useState("")
    const [customFieldValue, setCustomFieldValue] = useState("")

    const addCustomField = () => {
        if (customFieldName && customFieldValue) {
            const newCustomValue = [...customValuesParent, { customFieldName, customFieldValue }];
            setCustomValuesParent(newCustomValue)
            setCustomFieldName("")
            setCustomFieldValue("")
        }
    }

    const internalSubmit = () => {
        onSubmit()
    }

    const removeCustomField = (index) => {
        const newCustomValue = [...customValuesParent];
        newCustomValue.splice(index, 1)
        setCustomValuesParent(newCustomValue)
    }

    useEffect(() => {
        reset({ ...employeeData })
    }, [])

    useEffect(() => {
        if (stepData !== null) {
            setCustomValuesParent(stepData.map(item => ({ customFieldName: item?.key, customFieldValue: item?.value })))
        }
    }, [stepData])

    return (
        <form onSubmit={handleSubmit(internalSubmit)}>
            {customValuesParent.map((customField, index) =>
                <Grid container spacing={2} key={index}>
                    <Grid item xs={5} sx={{ mb: 2 }}>
                        <FormControl fullWidth size={"small"} variant="outlined">
                            <Typography>Custom Field Name</Typography>
                            <OutlinedInput
                                value={customField.customFieldName}
                                onChange={(e) => {
                                    const newValue = [...customValuesParent]
                                    newValue[index].customFieldName = e.target.value
                                    setCustomValuesParent(newValue)
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={5} sx={{ mb: 2 }}>
                        <FormControl fullWidth size={"small"} variant="outlined">
                            <Typography>Custom Field Value</Typography>
                            <OutlinedInput
                                value={customField.customFieldValue}
                                onChange={(e) => {
                                    const newValue = [...customValuesParent]
                                    newValue[index].customFieldValue = e.target.value
                                    setCustomValuesParent(newValue)
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <Stack justifyContent={"center"} sx={{ height: "100%" }}>
                            <Button onClick={() => removeCustomField(index)} color={"error"} sx={{ height: 40 }}
                                variant={"contained"}>Remove</Button>
                        </Stack>
                    </Grid>

                </Grid>
            )}
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <FormControl fullWidth variant="outlined" size={"small"}>
                        <Typography>Custom Field Name</Typography>
                        <OutlinedInput
                            value={customFieldName}
                            onChange={(e) => setCustomFieldName(e.target.value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={5}>
                    <FormControl fullWidth variant="outlined" size={"small"}>
                        <Typography>Custom Field Value</Typography>
                        <OutlinedInput
                            value={customFieldValue}
                            onChange={(e) => setCustomFieldValue(e.target.value)}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={2}>
                    <Stack sx={{ height: "100%" }} direction={"row"} alignItems={"end"} justifyContent={"center"}>
                        <Button fullWidth size={"small"} sx={{ height: 40 }} onClick={addCustomField} variant={"contained"}>Add</Button>
                    </Stack>
                </Grid>

            </Grid>
            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
                {/* <Button variant="contained" onClick={onPrev}>Previous</Button> */}
                <Button disabled={isUpdating} variant="contained" type={"submit"}>{isUpdating && <CircularProgress size={20} sx={{ mr: 2 }} />} Save</Button>

            </Stack>
        </form>
    )
}

export default PositionalTab;