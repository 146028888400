import Routes from "./routes";
import {BrowserRouter} from "react-router-dom";
import {HelmetProvider} from "react-helmet-async"
import {ProvideAuth} from "./hooks/useAuth";
import {ToastContainer} from "react-toastify";

function App() {

    return (
        <HelmetProvider>
                <BrowserRouter>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                    <ProvideAuth>
                        <Routes/>
                    </ProvideAuth>
                </BrowserRouter>
        </HelmetProvider>
    );
}

export default App;
