import { Controller, useForm } from "react-hook-form";
import {
    Button, CircularProgress, Divider,
    FormControl,
    FormHelperText, Grid,
    OutlinedInput,
    Stack, Typography
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { red } from "@mui/material/colors";
import { useEffect, useState } from "react";
import ReactSelect from "react-select";

const validationSchema = Yup.object().shape({});

const paymentMethodOptions = [
    {
        label: "Mobile Banking (Bkash)",
        value: "Bkash"
    },
    {
        label: "Mobile Banking (Nagad)",
        value: "Nagad"
    },
    {
        label: "Mobile Banking (Rocket)",
        value: 'Rocket'
    },
    {
        label: "Bank Account",
        value: "Bank"
    },
    {
        label: "Agent Banking",
        value: "Agent"
    },
    {
        label: "BEFTN",
        value: "BEFTN"
    },

]

const BankInfoTab = ({ onSubmit, onPrev, employeeData, stepData, isUpdating }) => {
    const [paymentMethod, setPaymentMethod] = useState('Bkash')
    //react-hook-form
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange",
        defaultValues: {
            paymentMethod: 'Bkash'
        }
    });

    if (stepData !== null) {
        setValue("paymentMethod", stepData.bank)
        setValue("mobileNumber", stepData.project_id)
        setValue("bankName", stepData.bank_name)
        setValue("branchName", stepData.branch_name)
        setValue("accountName", stepData.account_name)
        setValue("accountNumber", stepData.account_number)
        setValue("swiftCode", stepData.swift_code)
        setValue("routingNumber", stepData.routing_number)
    }

    useEffect(() => {
        reset({ ...employeeData })
    }, [employeeData, reset])


    const firstCondition = (paymentMethod === "Bkash" || paymentMethod === "Nagad" || paymentMethod === "Rocket" || paymentMethod === "Agent" || stepData?.bank === "Bkash" || stepData?.bank === "Nagad" || stepData?.bank === "Rocket" || stepData?.bank === "Agent");
    const secondCondition = (paymentMethod === "Bank" || paymentMethod === "BEFTN" || stepData?.bank === "Bank" || stepData?.bank === "BEFTN");


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={3}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth size="small">
                                        <Typography color={!!formState.errors?.paymentMethod ? red[700] : ""}>Select Payment
                                            Method*</Typography>
                                        <ReactSelect
                                            styles={{
                                                control: (base, state) => ({
                                                    ...base,
                                                    '&:hover': { borderColor: 'gray' }, // border style on hover
                                                    border: !!formState.errors?.paymentMethod ? '1px solid red' : '1px solid lightgray', // default border color
                                                    boxShadow: 'none', // no box-shadow
                                                }),
                                            }}
                                            {...field}
                                            options={paymentMethodOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            value={field?.value !== "" ? paymentMethodOptions?.filter(option => option?.value === field?.value)[0] : null}
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption?.value);
                                                setPaymentMethod(selectedOption.value);
                                            }}
                                        />
                                        {!!formState.errors?.paymentMethod ?
                                            <FormHelperText
                                                error>{errors?.paymentMethod?.message}</FormHelperText> : ""}
                                    </FormControl>
                                )}
                                name="paymentMethod"
                                control={control}
                                defaultValue={""}
                            />
                        </Grid>
                    </Grid>
                    <Divider sx={{ mt: 3 }} />
                </Grid>
                {secondCondition &&
                    <Grid item xs={12}>
                        <Typography color={`${red[700]}`} mb={1}>Required*</Typography>
                    </Grid>
                }

                {firstCondition &&
                    <Grid item xs={3}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth variant="outlined" size={"small"}>
                                    <Typography color={!!formState.errors?.accountNumber ? red[700] : ""}>Mobile Number*</Typography>
                                    <OutlinedInput
                                        {...field}
                                        required={firstCondition}
                                        error={!!formState.errors?.accountNumber}
                                    />
                                    {!!formState.errors?.accountNumber ?
                                        <FormHelperText
                                            error>{errors?.accountNumber?.message}</FormHelperText> : ""}

                                </FormControl>
                            )}
                            name="accountNumber"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                }
                {secondCondition &&
                    <>
                        <Grid item xs={3}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth variant="outlined" size={"small"}>
                                        <Typography color={!!formState.errors?.bankName ? red[700] : ""}>Bank
                                            Name</Typography>
                                        <OutlinedInput
                                            {...field}
                                            required={secondCondition}
                                            error={!!formState.errors?.bankName}
                                        />
                                        {!!formState.errors?.bankName ?
                                            <FormHelperText
                                                error>{errors?.bankName?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="bankName"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth variant="outlined" size={"small"}>
                                        <Typography color={!!formState.errors?.branchName ? red[700] : ""}>Branch
                                            Name</Typography>
                                        <OutlinedInput
                                            {...field}
                                            required={secondCondition}
                                            error={!!formState.errors?.branchName}
                                        />
                                        {!!formState.errors?.branchName ?
                                            <FormHelperText
                                                error>{errors?.branchName?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="branchName"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth variant="outlined" size={"small"}>
                                        <Typography color={!!formState.errors?.accountName ? red[700] : ""}>Account
                                            Name</Typography>
                                        <OutlinedInput
                                            {...field}
                                            required={secondCondition}
                                            error={!!formState.errors?.accountName}
                                        />
                                        {!!formState.errors?.accountName ?
                                            <FormHelperText
                                                error>{errors?.accountName?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="accountName"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth variant="outlined" size={"small"}>
                                        <Typography color={!!formState.errors?.accountNumber ? red[700] : ""}>Account
                                            Number</Typography>
                                        <OutlinedInput
                                            {...field}
                                            required={secondCondition}
                                            error={!!formState.errors?.accountNumber}
                                        />
                                        {!!formState.errors?.accountNumber ?
                                            <FormHelperText
                                                error>{errors?.accountNumber?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="accountNumber"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                    </>
                }
                {secondCondition &&
                    <>
                        <Grid item xs={3}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth variant="outlined" size={"small"}>
                                        <Typography color={!!formState.errors?.swiftCode ? red[700] : ""}>Swift
                                            Code</Typography>
                                        <OutlinedInput
                                            {...field}
                                            error={!!formState.errors?.swiftCode}
                                        />
                                        {!!formState.errors?.swiftCode ?
                                            <FormHelperText
                                                error>{errors?.swiftCode?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="swiftCode"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth variant="outlined" size={"small"}>
                                        <Typography color={!!formState.errors?.routingNumber ? red[700] : ""}>Routing
                                            Number</Typography>
                                        <OutlinedInput
                                            {...field}
                                            error={!!formState.errors?.routingNumber}
                                        />
                                        {!!formState.errors?.routingNumber ?
                                            <FormHelperText
                                                error>{errors?.routingNumber?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="routingNumber"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                    </>
                }
            </Grid>

            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
                {/* <Button variant="contained" onClick={onPrev}>Previous</Button> */}
                <Button disabled={isUpdating} variant="contained" type={"submit"}>{isUpdating && <CircularProgress size={20} sx={{ mr: 2 }} />} Save</Button>

            </Stack>
        </form>
    )
}

export default BankInfoTab;