import * as React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Divider, FormControl, FormHelperText, Grid, OutlinedInput, Slide, Typography, } from '@mui/material';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { red } from '@mui/material/colors';
import ReactSelect from 'react-select';
import { useState, useEffect } from 'react';
import Axios from '../../api/Axios';
import { useAuth } from '../../hooks/useAuth';
import { toast } from 'react-toastify';
import { getFilteringClients } from '../../api/commonApiCalls';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const validationSchema = Yup.object().shape({
    client: Yup.string().required("Required."),
    project: Yup.string().required("Required."),
    basic: Yup.string().required("Required."),
    transportAllowance: Yup.string().required("Required."),
    medicalAllowance: Yup.string().required("Required."),
    houseRent: Yup.string().required("Required."),
    mobileBill: Yup.string().required("Required."),
    conveyance: Yup.string().required("Required."),
    lunch: Yup.string().required("Required."),
});


const SalaryConfigDialog = ({ openDialog, setOpenDialog, metadata = {} }) => {
    const { id } = metadata;
    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(validationSchema), mode: "onChange", defaultValues: {
            client: "",
            project: "",
            basic: "",
            transportAllowance: "",
            medicalAllowance: "",
            houseRent: "",
            mobileBill: "",
            conveyance: "",
            lunch: "",
        }
    });

    const { token, role } = useAuth();
    const [projects, setProjects] = useState([])
    const [additionalInfo, setAdditionalInfo] = useState({})
    const [loading, setLoading] = useState(true)
    const [filteringClients, setFilteringClients] = useState([])


    const onSubmit = (_data) => {
        console.log(_data)
    }
    console.log(id)

    //Getting Projects data
    useEffect(() => {
        const getProjects = async () => {
            let link = `/project?page=${1}`;
            try {
                setLoading(true)
                const res = await Axios.get(link, {
                    headers: {
                        Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                    }
                });
                if (res.data.status === "success") {
                    setProjects(res.data.response_data.data)
                    const { data, ...rest } = res.data.response_data;
                    setAdditionalInfo(rest)
                } else {
                    setProjects([])
                    toast.warn("Something went wrong")
                }
                setLoading(false)
            } catch (error) {
                setProjects([])
                console.log(error)
                toast.warn("Something went wrong")
                setLoading(false)
            }
        }

        getProjects();
    }, [token])



    useEffect(() => {
        getFilteringClients(setLoading, token, setFilteringClients)
    }, [token])

    return (
        <Dialog maxWidth="sm" fullWidth onClose={() => setOpenDialog(!openDialog)} open={openDialog}
            TransitionComponent={Transition}
            TransitionProps={{
                timeout: 500,
            }}
            PaperProps={{
                style: {
                    position: 'fixed',
                    top: 0,
                },
            }}>
            <DialogTitle>{id ? "Edit Salary Percentage Configuration" : "Add Salary Percentage Configuration"} </DialogTitle>
            <Divider />
            <DialogContent>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth>
                                        <Typography
                                            color={!!formState.errors?.client ? red[700] : ""}
                                        >
                                            Select Client *
                                        </Typography>
                                        <ReactSelect
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    border: !!formState.errors?.client
                                                        ? "1px solid #D32F2F"
                                                        : "1px solid #C4C4C4",
                                                    "&:hover": {
                                                        border: !!formState.errors?.client
                                                            ? "1px solid #D32F2F"
                                                            : "1px solid #C4C4C4",
                                                    },
                                                }),
                                            }}
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isLoading={loading}
                                            isClearable={true}
                                            isSearchable={true}
                                            name="color"
                                            options={filteringClients}
                                            // value={
                                            //     field?.value !== ""
                                            //         ? clients?.filter(
                                            //             (item) => item?.id === field?.value
                                            //         )[0]
                                            //         : null
                                            // }
                                            onChange={(selectedOption) =>
                                                field.onChange(selectedOption?.value)
                                            }
                                        />
                                        {!!formState.errors?.client ? (
                                            <FormHelperText error>
                                                {formState.errors?.client?.message}
                                            </FormHelperText>
                                        ) : (
                                            ""
                                        )}
                                    </FormControl>
                                )}
                                name="client"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth>
                                        <Typography
                                            color={!!formState.errors?.project ? red[700] : ""}
                                        >
                                            Select Project *
                                        </Typography>
                                        <ReactSelect
                                            styles={{
                                                control: (base) => ({
                                                    ...base,
                                                    border: !!formState.errors?.project
                                                        ? "1px solid #D32F2F"
                                                        : "1px solid #C4C4C4",
                                                    "&:hover": {
                                                        border: !!formState.errors?.project
                                                            ? "1px solid #D32F2F"
                                                            : "1px solid #C4C4C4",
                                                    },
                                                }),
                                            }}
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isLoading={loading}
                                            isClearable={true}
                                            isSearchable={true}
                                            name="color"
                                            options={projects?.map(item => ({ value: item.project_fdb_id, label: item.name }))}
                                            // value={
                                            //     field?.value !== ""
                                            //         ? projects?.filter(
                                            //             (item) => item?.id === field?.value
                                            //         )[0]
                                            //         : null
                                            // }
                                            onChange={(selectedOption) =>
                                                field.onChange(selectedOption?.value)
                                            }
                                        />
                                        {!!formState.errors?.project ? (
                                            <FormHelperText error>
                                                {formState.errors?.project?.message}
                                            </FormHelperText>
                                        ) : (
                                            ""
                                        )}
                                    </FormControl>
                                )}
                                name="project"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>


                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth variant="outlined" size={"small"}>
                                        <Typography color={!!formState.errors?.basic ? red[700] : ""}>Basic Salary (%) *</Typography>
                                        <OutlinedInput
                                            {...field}
                                            error={!!formState.errors?.basic}
                                            type="number"
                                            onChange={e => {
                                                field.onChange(e);

                                            }}
                                        />
                                        {!!formState.errors?.basic ?
                                            <FormHelperText
                                                error>{formState.errors?.basic?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="basic"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth variant="outlined" size={"small"}>
                                        <Typography color={!!formState.errors?.transportAllowance ? red[700] : ""}>Transport
                                            Allowance (%) *</Typography>
                                        <OutlinedInput
                                            {...field}
                                            type={"number"}
                                            error={!!formState.errors?.transportAllowance}
                                            onChange={e => {
                                                field.onChange(e);

                                            }}
                                        />
                                        {!!formState.errors?.transportAllowance ?
                                            <FormHelperText
                                                error>{formState.errors?.transportAllowance?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="transportAllowance"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth variant="outlined" size={"small"}>
                                        <Typography color={!!formState.errors?.medicalAllowance ? red[700] : ""}>Medical
                                            Allowance (%) *</Typography>
                                        <OutlinedInput
                                            {...field}
                                            type={"number"}
                                            error={!!formState.errors?.medicalAllowance}
                                            onChange={e => {
                                                field.onChange(e);

                                            }}
                                        />
                                        {!!formState.errors?.medicalAllowance ?
                                            <FormHelperText
                                                error>{formState.errors?.medicalAllowance?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="medicalAllowance"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth variant="outlined" size={"small"}>
                                        <Typography color={!!formState.errors?.houseRent ? red[700] : ""}>House
                                            Rent (%) *</Typography>
                                        <OutlinedInput
                                            {...field}
                                            type={"number"}
                                            error={!!formState.errors?.houseRent}
                                            onChange={e => {
                                                field.onChange(e);

                                            }}
                                        />
                                        {!!formState.errors?.houseRent ?
                                            <FormHelperText
                                                error>{formState.errors?.houseRent?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="houseRent"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth variant="outlined" size={"small"}>
                                        <Typography color={!!formState.errors?.mobileBill ? red[700] : ""}>Mobile
                                            Bill (%) *</Typography>
                                        <OutlinedInput
                                            {...field}
                                            type={"number"}
                                            error={!!formState.errors?.mobileBill}
                                            onChange={e => {
                                                field.onChange(e);

                                            }}
                                        />
                                        {!!formState.errors?.mobileBill ?
                                            <FormHelperText
                                                error>{formState.errors?.mobileBill?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="mobileBill"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth variant="outlined" size={"small"}>
                                        <Typography color={!!formState.errors?.conveyance ? red[700] : ""}>Conveyance
                                            Bill (%) *</Typography>
                                        <OutlinedInput
                                            {...field}
                                            type={"number"}
                                            error={!!formState.errors?.conveyance}
                                            onChange={e => {
                                                field.onChange(e);

                                            }}
                                        />
                                        {!!formState.errors?.conveyance ?
                                            <FormHelperText
                                                error>{formState.errors?.conveyance?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="conveyance"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth variant="outlined" size={"small"}>
                                        <Typography color={!!formState.errors?.lunch ? red[700] : ""}>Lunch
                                            Allowance (%) *</Typography>
                                        <OutlinedInput
                                            {...field}
                                            type={"number"}
                                            error={!!formState.errors?.lunch}
                                            onChange={e => {
                                                field.onChange(e);
                                            }}
                                        />
                                        {!!formState.errors?.lunch ?
                                            <FormHelperText
                                                error>{formState.errors?.lunch?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="lunch"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>

                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        sx={{ my: 2 }}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                onClick={() => reset()}
                                color="inherit"
                                fullWidth
                            >
                                Reset
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                type="submit"
                                color="success"
                                fullWidth
                            >
                                Add
                            </Button>
                        </Grid>
                    </Grid>
                </form>

            </DialogContent>
        </Dialog>
    );
}


export default SalaryConfigDialog;