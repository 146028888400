import { Controller, useForm } from "react-hook-form";
import {
    Button, CircularProgress, Divider,
    FormControl,
    FormHelperText, Grid,
    OutlinedInput,
    Stack, TextField, Typography
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { red } from "@mui/material/colors";
import { useEffect, useState } from "react";
import countryList from "../../_mockData/countries"
import ReactSelect from "react-select"
import { getFilteringClients, getFilteringProjects } from "../../api/commonApiCalls";
import { useAuth } from "../../hooks/useAuth";
import Axios from "../../api/Axios";
import { toast } from "react-toastify";
import moment from "moment";
import LoadingPage from "../../Pages/LoadingPage/LoadingPage";

const employeeOption = [
    { label: "Intern", value: "Intern" },
    { label: "Fulltime", value: "Fulltime" },
    { label: "Part-time", value: "Parttime" },
    { label: "Contractual", value: "Contractual" }
]

const validationSchema = Yup.object().shape({
    client: Yup.string().required("Client is required"),
    project: Yup.string().required("Project is required"),
    employeeName: Yup.string().required("Employee name is required").min(3, "Minimum 3 characters"),
    // fatherName: Yup.string().required("Father name is required").min(3, "Minimum 3 characters"),
    // motherName: Yup.string().required("Mother name is required").min(3, "Minimum 3 characters"),
    email: Yup.string(),
    // phone: Yup.string().required("Phone is required").min(7, "Minimum 7 digits"),
    country: Yup.string().required("Country is required"),
    //attendanceTime: Yup.string().required("Attendance time is required"),
    employeeType: Yup.string().required("Employee Type is required"),
    // presentAddress: Yup.string().required("Present address is required"),
    // permanentAddress: Yup.string().required("Permanent address is required"),
});

const BasicInfoTab = ({ onSubmit, employeeData, stepData, employeeId, isUpdating }) => {
    const [loading, setLoading] = useState(true)
    const [clients, setClients] = useState([])
    const [projects, setProjects] = useState([])
    const [schedules, setSchedules] = useState([])
    const [selectedFilterClient, setSelectedFilterClient] = useState(null)
    const [selectedProject, setSelectedProject] = useState(null)

    const { token } = useAuth();

    //react-hook-form
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        watch
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange",
        defaultValues: {
            country: 'BD'
        }
    });

    useEffect(() => {
        if (stepData !== null) {
            setValue("client", stepData.hrm_client_id)
            setValue("project", stepData.project_id)
            setValue("fdbEmployeId", stepData.hrm_employee_id)
            setValue("employeeName", stepData.name)
            setValue("fatherName", stepData.father_name)
            setValue("motherName", stepData.mother_name)
            setValue("email", stepData?.email || "")
            setValue("homeEmail", stepData.home_email)
            setValue("businessEmail", stepData.business_email)
            setValue("phone", stepData.phone)
            setValue("homePhone", stepData.home_phone)
            setValue("businessPhone", stepData.business_phone)
            setValue("country", stepData.country)
            setValue("city", stepData.city)
            setValue("zip", stepData.zip_code)
            setValue("attendanceTime", stepData.attendance_schedule_id)
            setValue("employeeType", stepData.type)
            setValue("eidClient", stepData.employee_client_id)
            setValue("presentAddress", stepData.present_address)
            setValue("permanentAddress", stepData.parmanent_address)
            setSelectedProject(stepData.project_id);
        }
    }, [stepData, setValue])


    // useEffect(() => {
    //     reset({ ...employeeData })
    // }, [employeeData, reset])

    //get client list
    useEffect(() => {
        getFilteringClients(setLoading, token, setClients)
    }, [token])
    //get project list
    useEffect(() => {
        getFilteringProjects(setLoading, token, setProjects, selectedFilterClient?.value);
    }, [selectedFilterClient, token]);

    //get office schedule
    useEffect(() => {
        const getSchedule = async () => {
            setLoading(true)
            try {
                const res = await Axios.get(`employee/schedule?project_id=${selectedProject}`, {
                    headers: {
                        Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                    }
                });
                if (res.data?.status === "success") {
                    setSchedules(res.data.response_data.map(schedule => ({
                        label: `${schedule?.shift} - ${moment(schedule?.start_time, [moment.ISO_8601, 'HH:mm']).format("hh:mm A")} to ${moment(schedule?.end_time, [moment.ISO_8601, 'HH:mm']).format("hh:mm A")}`,
                        value: schedule.id
                    })))
                } else {
                    setSchedules([])
                    toast.warn("Office schedule is not available")
                }
                setLoading(false)
            } catch (error) {
                if (error?.response?.data?.message === "Data not found.") {
                    toast.warn("Office schedule is not available for this project")
                    setSchedules([])
                } else {
                    console.log(error)
                }
                setLoading(false)

            }
        }
        if (selectedProject !== null && selectedProject !== undefined) {
            getSchedule()
        }
    }, [selectedProject, token])


    const clientId = watch('client');
    const projectId = watch('project');

    return (
        <>
            {loading ?
                <LoadingPage /> :
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={stepData !== null ? 3 : 4}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl size={"small"} fullWidth>
                                        <Typography color={!!formState.errors?.client ? red[700] : ""}>Client*</Typography>
                                        <ReactSelect
                                            styles={
                                                {
                                                    control: (base) => ({
                                                        ...base,
                                                        border: !!formState.errors?.client ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                        "&:hover": {
                                                            border: !!formState.errors?.client ? "1px solid #D32F2F" : "1px solid #C4C4C4"
                                                        }
                                                    })
                                                }
                                            }
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isClearable={true}
                                            isSearchable={true}
                                            name="client"
                                            options={clients}
                                            value={clientId ? clients?.find(option => option?.value === Number(clientId)) : field?.value !== "" ? clients?.filter(option => option?.value === field?.value)[0] : null}
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption?.value);
                                                setSelectedFilterClient(selectedOption);
                                                setValue("project", "")
                                            }}
                                        />
                                        {!!formState.errors?.client ?
                                            <FormHelperText
                                                error>{errors?.client?.message}</FormHelperText> : ""}
                                    </FormControl>
                                )}
                                name="client"
                                control={control}
                                defaultValue={""}
                            />
                        </Grid>
                        <Grid item xs={stepData !== null ? 3 : 4}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl size={"small"} fullWidth>
                                        <Typography
                                            color={!!formState.errors?.project ? red[700] : ""}>Project*</Typography>
                                        <ReactSelect
                                            styles={
                                                {
                                                    control: (base) => ({
                                                        ...base,
                                                        border: !!formState.errors?.project ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                        "&:hover": {
                                                            border: !!formState.errors?.project ? "1px solid #D32F2F" : "1px solid #C4C4C4"
                                                        }
                                                    })
                                                }
                                            }
                                            isDisabled={selectedFilterClient === null || projects.length === 0}
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isClearable={true}
                                            isSearchable={true}
                                            name="project"
                                            options={projects}
                                            value={projectId ? projects?.find(option => option?.value === Number(projectId)) : field?.value !== "" ? projects?.filter(option => option?.value === field?.value)[0] : null}
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption?.value)
                                                setSelectedProject(selectedOption?.value)
                                            }}
                                        />
                                        {!!formState.errors?.project ?
                                            <FormHelperText
                                                error>{errors?.project?.message}</FormHelperText> : ""}
                                    </FormControl>
                                )}
                                name="project"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        {stepData !== null &&
                            <Grid item xs={stepData !== null ? 3 : 4}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl size={"small"} fullWidth variant="outlined">
                                            <Typography color={!!formState.errors?.fdbEmployeId ? red[700] : ""}>FDB
                                                Employee ID</Typography>
                                            <OutlinedInput
                                                {...field}
                                                error={!!formState.errors?.fdbEmployeId}
                                            />
                                            {!!formState.errors?.fdbEmployeId ?
                                                <FormHelperText
                                                    error>{errors?.fdbEmployeId?.message}</FormHelperText> : ""}

                                        </FormControl>
                                    )}
                                    name="fdbEmployeId"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                        }
                        <Grid item xs={stepData !== null ? 3 : 4}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl size={"small"} fullWidth variant="outlined">
                                        <Typography color={!!formState.errors?.eidClient ? red[700] : ""}>EID
                                            Client</Typography>
                                        <OutlinedInput
                                            {...field}
                                            error={!!formState.errors?.eidClient}
                                        />
                                        {!!formState.errors?.eidClient ?
                                            <FormHelperText
                                                error>{errors?.eidClient?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="eidClient"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                    </Grid>
                    <Divider sx={{ mt: 3, mb: 2 }} />
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl size={"small"} fullWidth variant="outlined">
                                        <Typography color={!!formState.errors?.employeeName ? red[700] : ""}>Employee
                                            Name*</Typography>
                                        <OutlinedInput
                                            {...field}
                                            error={!!formState.errors?.employeeName}
                                        />
                                        {!!formState.errors?.employeeName ?
                                            <FormHelperText
                                                error>{errors?.employeeName?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="employeeName"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl size={"small"} fullWidth variant="outlined">
                                        <Typography color={!!formState.errors?.fatherName ? red[700] : ""}>Father
                                            Name</Typography>
                                        <OutlinedInput
                                            {...field}
                                            error={!!formState.errors?.fatherName}
                                        />
                                        {!!formState.errors?.fatherName ?
                                            <FormHelperText
                                                error>{errors?.fatherName?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="fatherName"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl size={"small"} fullWidth variant="outlined">
                                        <Typography color={!!formState.errors?.motherName ? red[700] : ""}>Mother
                                            Name</Typography>
                                        <OutlinedInput
                                            {...field}
                                            error={!!formState.errors?.motherName}
                                        />
                                        {!!formState.errors?.motherName ?
                                            <FormHelperText
                                                error>{errors?.motherName?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="motherName"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl size={"small"} fullWidth variant="outlined">
                                        <Typography color={!!formState.errors?.email ? red[700] : ""}>Email address</Typography>
                                        <OutlinedInput
                                            {...field}
                                            error={!!formState.errors?.email}
                                        />
                                        {!!formState.errors?.email ?
                                            <FormHelperText
                                                error>{errors?.email?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="email"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl size={"small"} fullWidth variant="outlined">
                                        <Typography color={!!formState.errors?.homeEmail ? red[700] : ""}>Home
                                            Email</Typography>
                                        <OutlinedInput
                                            {...field}
                                            error={!!formState.errors?.homeEmail}
                                        />
                                        {!!formState.errors?.homeEmail ?
                                            <FormHelperText
                                                error>{errors?.homeEmail?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="homeEmail"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl size={"small"} fullWidth variant="outlined">
                                        <Typography color={!!formState.errors?.businessEmail ? red[700] : ""}>Business
                                            Email</Typography>
                                        <OutlinedInput
                                            {...field}
                                            error={!!formState.errors?.businessEmail}
                                        />
                                        {!!formState.errors?.businessEmail ?
                                            <FormHelperText
                                                error>{errors?.businessEmail?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="businessEmail"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl size={"small"} fullWidth variant="outlined">
                                        <Typography color={!!formState.errors?.phone ? red[700] : ""}>Phone</Typography>
                                        <OutlinedInput
                                            {...field}
                                            error={!!formState.errors?.phone}
                                        />
                                        {!!formState.errors?.phone ?
                                            <FormHelperText
                                                error>{errors?.phone?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="phone"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl size={"small"} fullWidth variant="outlined">
                                        <Typography color={!!formState.errors?.homePhone ? red[700] : ""}>Home
                                            Phone</Typography>
                                        <OutlinedInput
                                            {...field}
                                            error={!!formState.errors?.homePhone}
                                        />
                                        {!!formState.errors?.homePhone ?
                                            <FormHelperText
                                                error>{errors?.homePhone?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="homePhone"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl size={"small"} fullWidth variant="outlined">
                                        <Typography color={!!formState.errors?.businessPhone ? red[700] : ""}>Business
                                            Phone</Typography>
                                        <OutlinedInput
                                            {...field}
                                            error={!!formState.errors?.businessPhone}
                                        />
                                        {!!formState.errors?.businessPhone ?
                                            <FormHelperText
                                                error>{errors?.businessPhone?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="businessPhone"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl size={"small"} fullWidth variant="outlined">
                                        <Typography color={!!formState.errors?.presentAddress ? red[700] : ""}>Present Address</Typography>
                                        <TextField
                                            {...field}
                                            error={!!formState.errors?.presentAddress}
                                            multiline
                                            rows={4}
                                        />
                                        {!!formState.errors?.presentAddress ?
                                            <FormHelperText
                                                error>{errors?.presentAddress?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="presentAddress"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl size={"small"} fullWidth variant="outlined">
                                        <Typography color={!!formState.errors?.permanentAddress ? red[700] : ""}>Permanent Address</Typography>
                                        <TextField
                                            {...field}
                                            error={!!formState.errors?.permanentAddress}
                                            multiline
                                            rows={4}
                                        />
                                        {!!formState.errors?.permanentAddress ?
                                            <FormHelperText
                                                error>{errors?.permanentAddress?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="permanentAddress"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth>
                                        <Typography
                                            color={!!formState.errors?.country ? red[700] : ""}>Country*</Typography>
                                        <ReactSelect
                                            styles={
                                                {
                                                    control: (base) => ({
                                                        ...base,
                                                        border: !!formState.errors?.country ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                        "&:hover": {
                                                            border: !!formState.errors?.country ? "1px solid #D32F2F" : "1px solid #C4C4C4"
                                                        }
                                                    })
                                                }
                                            }
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isClearable={true}
                                            isSearchable={true}
                                            name="country"
                                            options={countryList}
                                            getOptionValue={(option) => option.id}
                                            value={field?.value !== "" ? countryList?.filter(country => country?.id === field?.value)[0] : {}}
                                            onChange={(selectedOption) => field.onChange(selectedOption?.id)}
                                        />
                                        {!!formState.errors?.country ?
                                            <FormHelperText
                                                error>{errors?.country?.message}</FormHelperText> : ""}
                                    </FormControl>
                                )}
                                name="country"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl fullWidth variant="outlined">
                                        <Typography color={!!formState.errors?.city ? red[700] : ""}>City</Typography>
                                        <OutlinedInput
                                            size={"small"}
                                            {...field}
                                            error={!!formState.errors?.city}
                                        />
                                        {!!formState.errors?.city ?
                                            <FormHelperText
                                                error>{errors?.city?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="city"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl size={"small"} fullWidth variant="outlined">
                                        <Typography color={!!formState.errors?.zip ? red[700] : ""}>ZIP Code</Typography>
                                        <OutlinedInput
                                            {...field}
                                            error={!!formState.errors?.zip}
                                        />
                                        {!!formState.errors?.zip ?
                                            <FormHelperText
                                                error>{errors?.zip?.message}</FormHelperText> : ""}

                                    </FormControl>
                                )}
                                name="zip"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl size={"small"} fullWidth>
                                        <Typography color={!!formState.errors?.attendanceTime ? red[700] : ""}>Attendance
                                            Time</Typography>
                                        <ReactSelect
                                            styles={
                                                {
                                                    control: (base) => ({
                                                        ...base,
                                                        border: !!formState.errors?.attendanceTime ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                        "&:hover": {
                                                            border: !!formState.errors?.attendanceTime ? "1px solid #D32F2F" : "1px solid #C4C4C4"
                                                        }
                                                    })
                                                }
                                            }
                                            isDisabled={selectedProject === null || schedules.length === 0}
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isClearable={true}
                                            isSearchable={true}
                                            name="attendanceTime"
                                            options={schedules}
                                            value={field?.value !== "" ? schedules?.filter(option => option?.value === field?.value)[0] : null}
                                            onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                                        />
                                        {!!formState.errors?.attendanceTime ?
                                            <FormHelperText
                                                error>{errors?.attendanceTime?.message}</FormHelperText> : ""}
                                    </FormControl>
                                )}
                                name="attendanceTime"
                                control={control}
                                defaultValue=""
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                render={({ field, formState }) => (
                                    <FormControl size={"small"} fullWidth>
                                        <Typography color={!!formState.errors?.employeeType ? red[700] : ""}>Employee
                                            Type*</Typography>
                                        <ReactSelect
                                            styles={
                                                {
                                                    control: (base) => ({
                                                        ...base,
                                                        border: !!formState.errors?.employeeType ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                        "&:hover": {
                                                            border: !!formState.errors?.employeeType ? "1px solid #D32F2F" : "1px solid #C4C4C4"
                                                        }
                                                    })
                                                }
                                            }
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isClearable={true}
                                            isSearchable={true}
                                            name="employeeType"
                                            options={employeeOption}
                                            value={field?.value !== "" ? employeeOption?.filter(option => option?.value === field?.value)[0] : setValue("employeeType", "Fulltime")}
                                            onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                                        />
                                        {!!formState.errors?.employeeType ?
                                            <FormHelperText
                                                error>{errors?.employeeType?.message}</FormHelperText> : ""}
                                    </FormControl>
                                )}
                                name="employeeType"
                                control={control}
                            />
                        </Grid>
                    </Grid>

                    <Stack direction="row" justifyContent="end" sx={{ mt: 2 }}>
                        <Button disabled={isUpdating} variant="contained" type={"submit"}>{isUpdating && <CircularProgress size={20} sx={{ mr: 2 }} />} Save</Button>
                    </Stack>
                </form>
            }
        </>
    )
}

export default BasicInfoTab;